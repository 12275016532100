import axios from "axios";

const state = {
    processes: [],
    process: "",
};

const getters = {
    allProcesses: (state) => state.processes,
    process: (state) => state.process,
};
const actions = {
    fetchProcesses({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .post(filter.pagiurl, filter)
                .then((response) => {
                    resolve(response);
                    commit("setProcess", response.data.message.data);
                })
                .catch((error) => {
                    reject(error.response.data.errors);
                });
        });
    },
    async addProcess({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/process/create", assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("newProcess", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateProcess({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/process/update/" + assign.id, assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("updateProcess", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async returnOldDataProcess({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/process/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataProcess",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    deleteProcess({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/process/delete/" + id, {})
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("removeProcess", id);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    setProcess: (state, payload) => (state.processes = payload),
    newProcess: (state, payload) => state.processes.unshift(payload),
    removeProcess: (state, id) =>
        (state.processes = state.processes.filter(
            (account) => account.id !== id
        )),
    updateProcess(state, payload) {
        state.processes = state.processes.map((process) => {
            if (process.id === payload.id) {
                return Object.assign({}, process, payload);
            }
            return process;
        });
    },
    addReturnOldDataProcess: (state, payload) =>
        state.processes.unshift(payload),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
