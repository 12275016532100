import axios from "axios";

const state = {
    usernames: [],
    users: [],
    user: "",
};

const getters = {
    allUsernames: (state) => state.usernames,
    allUsers: (state) => state.users,
    user: (state) => state.user,
};
const actions = {
    async getAllUsernames({ commit }, body) {
        return await axios.post("/api/users", body).then((response) => {
            commit("setAllUsernames", response.data.message.data);
            return response.data.message;
        });
    },
    async getRoles({ commit }) {
        return await axios.get("/api/role-selector").then((response) => {
            return response.data;
        });
    },
    async getPositionSelector({ commit }) {
        return await axios.get("/api/position-selector").then((response) => {
            return response.data;
        });
    },

    // async getPointUser({ commit }, numPage) {
    //     return await axios.get('/api/coin-log?page=' + numPage)
    //         .then((response) => {
    //             return response.data
    //         })
    //         .catch((error) => {
    //             return error
    //         })
    // },
    // changeUserStatus({ commit }, assign) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/api/user/update-status/' + assign.id, assign)
    //             .then((response) => {
    //                 resolve(response)
    //                 if (response.data.success) {
    //                     commit('updateUser', response.data.new_data);
    //                 }

    //             })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // },
    async updateUser({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/user/update/" + assign.id, assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("updateUser", response.data.new_data);
                        // return response.data.new_data
                    }
                })
                .catch((error) => {
                    reject(error);
                    // return error
                });
        });
    },
    // updatePermission({ commit }, assign) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/api/user/update-permission/' + assign.id, assign)
    //             .then((response) => {
    //                 resolve(response)
    //                 if (response.data.success) {
    //                     commit('updateUser', response.data.new_data);
    //                 }

    //             })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // },
    async addUser({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/user/create", assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("newUser", response.data.new_data);
                        // return response.data.new_data
                    }
                })
                .catch((error) => {
                    reject(error);
                    // return error
                });
        });
    },
    fetchUsers({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .post(filter.pagiurl, filter)
                .then((response) => {
                    resolve(response);
                    commit("setUsers", response.data.message.data);
                })
                .catch((error) => {
                    reject(error.response.data.errors);
                });
        });
    },
    deleteUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/user/delete/" + id, {})
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("removeUser", id);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async returnOldDataUser({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/user/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("addReturnOldDataUser", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    setAllUsernames: (state, payload) => (state.users = payload),
    setUsers: (state, users) => (state.users = users),
    newUser: (state, payload) => state.users.unshift(payload),
    removeUser: (state, id) =>
        (state.users = state.users.filter((account) => account.id !== id)),
    updateUser(state, payload) {
        state.users = state.users.map((user) => {
            if (user.id === payload.id) {
                return Object.assign({}, user, payload);
            }
            return user;
        });
    },
    addReturnOldDataUser: (state, payload) => state.users.unshift(payload),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
