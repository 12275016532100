<template>
  <div>
    <!-- Modal -->
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="coffeevarietyModal"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{
                editMode
                  ? translate("menu.edit_name_coffee_variety_data")
                  : translate("menu.add_name_coffee_variety_data")
              }}
            </h5>
            <button
              type="button"
              class="btn btn-outline-warning custom_btn"
              data-dismiss="modal"
            >
              {{ translate("menu.close") }}
            </button>
          </div>
          <form @submit.prevent="editMode ? updateData() : addData()">
            <div class="modal-body">
              <div class="bg-danger">
                {{ errorMsg }}
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="name" class="required">{{
                    translate("menu2.name")
                  }}</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="form-control"
                    :placeholder="translate('menu.Name')"
                    v-model="form.name"
                    :class="$errorBorder('name')"
                    autocomplete="off"
                  />
                  <div :class="$errorText('name')">
                    {{ errors.name }}
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-info custom_btn"
                data-dismiss="modal"
              >
                {{ translate("menu2.close") }}
              </button>
              <button type="submit" class="btn btn-primary custom_btn">
                {{ translate("menu2.save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var inputData = {
  id: "",
  name: "",
};
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    $(this.$refs.coffeevarietyModal).on("hidden.bs.modal", () => {
      this.$resetForm(this.errors, this.form);
    });
  },
  data() {
    return {
      editMode: false,
      form: { ...inputData },
      errorMsg: "",
      errors: { ...inputData },
      roles: [],
    };
  },

  watch: {},
  methods: {
    ...mapActions([
      "createVarieties",
      "updateVarieties",
      "returnOldDataVarieties",
    ]),
    openModal() {
      this.editMode = false;
      $(this.$refs.coffeevarietyModal).modal("show");
    },
    openModalEdit(user) {
      this.$setFormToEdit(user, this.form);
      this.editMode = true;
      $(this.$refs.coffeevarietyModal).modal("show");
    },
    updateData() {
      let CUpath = this.updateVarieties(this.form);
      this.CU(CUpath);
    },
    addData() {
      let CUpath = this.createVarieties(this.form);
      this.CU(CUpath);
    },
    CU(CUpath) {
      $(".loader").show();
      CUpath.then((response) => {
        $(".loader").hide();
        if (response.data.success) {
          swal.fire(
            this.editMode ? "Updated!" : "Added!",
            response.data.message,
            "success"
          );
          this.editMode = false;
          $(this.$refs.coffeevarietyModal).modal("hide");
          //   Fire.$emit("doneLocation");
        } else {
          //****************return deleted data*********************
          //****************end of return deleted data***************
          if (this.$errorMsg(response.data.message)) {
            if (response.data.existed_data) {
              this.returnOldData(
                response.data.existed_data,
                response.data.message
              );
            } else {
              swal.fire(
                this.translate("menu2.error"),
                response.data.message,
                "error"
              );
            }
          }
          this.$validateMsg(response.data.message);
        }
      }).catch((error) => {
        if (error.response) {
          swal.fire(
            this.translate("menu2.error"),
            error.response.data.message,
            "error"
          );
        }
      });
    },
    returnOldData(data, message) {
      swal
        .fire({
          title: "",
          // text: `DO YOU REALLY WANT TO DELETE ${data.name} FROM POSITION INFORMATION`,
          text: message,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.translate("menu.cancel"),
          confirmButtonText: this.translate("menu2.yes_return"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.returnOldDataVarieties(data).then((res) => {
              if (res.data.success) {
                $(this.$refs.coffeevarietyModal).modal("hide");
                swal.fire("", res.data.message, "success");
              }
            });
          }
        });
    },
  },
};
</script>

<style lang="sass">
</style>
