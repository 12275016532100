<template>
  <div>
    <div class="pt-4">
      <div class="form-group row">
        <label for="name" class="col-md-4 col-form-label text-md-right">
          Choose excel file
        </label>
        <div class="col-md-4">
          <div class="cu_file_input">
            <button
              class="btn btn-secondary fate_btn"
              @click="$refs.import_file.click()"
            >
              Choose File
            </button>
            <input
              type="file"
              class="form-control"
              :class="{ ' is-invalid': errors.message }"
              id="input-file-import"
              name="file_import"
              ref="import_file"
              @change="onFileChange"
            />
          </div>
          <div v-if="errors.message" class="invalid-feedback"></div>
        </div>
      </div>
      <div class="form-group row">
        <label for="name" class="col-md-4 col-form-label text-md-right"></label>
        <div class="col-md-4">
          <button
            @click="uploadEx()"
            class="btn btn-primary"
            :disabled="import_file ? false : true"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  mounted() {},
  data() {
    return {
      errors: { profile_name: "", message: "" },
      import_file: "",
      profile_name: "",
      datas: [],

      pagination: {},
      pagi: "",
      pgn_num: "",
      filter: {
        all: "",
        pagiurl: "",

        listNum: "",
        search: "",
      },
      isInputSearch: false,
    };
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.search) {
          this.isInputSearch = true;
        } else {
          this.isInputSearch = false;
        }
      },
    },
  },
  methods: {
    onFileChange(e) {
      this.import_file = e.target.files[0];
    },

    uploadEx() {
      console.log($("#input-file-import").val());
      $(".loader").show();
      let formData = new FormData();
      formData.append("import_file", this.import_file);

      axios
        // .post("/api/excel/import", formData)
        .post("/api/excel/import", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          $(".loader").hide();
          if (response.data.success) {
            swal.fire("Saved!", response.data.message, "success");
          } else {
            swal.fire("", response.data.message, "error");
          }
        })
        .catch((error) => {
          if (error.response) {
            swal.fire(
              this.translate("menu2.error"),
              error.response.data.message,
              "warning"
            );
          }
        });
    },
  },
};
</script>
<style lang="scss">
.cu_file_input {
  position: relative;
  .fate_btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 105px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.excel_card {
  background-image: url("/img/xl.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
</style>
