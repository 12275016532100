import Vuex from "vuex";
import Vue from "vue";
import login from "./modules/login";
import user from "./modules/user";
import coffee from "./modules/coffee"
import address from "./modules/address"
import location from "./modules/location"
import process from "./modules/process"

Vue.use(Vuex);

//Create store
export default new Vuex.Store({
    modules: {
        user,
        coffee,
        address,
        location,
        process,
    }
});
