require("./bootstrap");

window.Vue = require("vue").default;
import { mapGetters, mapActions } from "vuex";
import VueRouter from "vue-router";
import routes from "./router";
import store from "./store";

import VueProgressBar from "vue-progressbar";
import Swal from "sweetalert2";
import MyPlug from "./helpers/MyPlug";
import AxiosPlugin from "vue-axios-cors";
import VueCookies from "vue-cookies";

import { Howl, Howler } from "howler";

window.Fire = new Vue();
Vue.use(MyPlug);
Vue.use(VueRouter);

Vue.use(AxiosPlugin);
Vue.use(VueCookies);

Vue.component("forget-password", require("./forgetpassword/ForgetPassword.vue").default)

const router = new VueRouter({
    mode: "history",
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active"
});

window.swal = Swal;
const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
});
window.toast = Toast;

Vue.use(VueProgressBar, {
    color: "#38c172",
    failedColor: "#FF5A5F",
    thickness: "10px"
});
window.tranlate = require("./VueTranslation/Translation").default.translate;
Vue.prototype.translate = require("./VueTranslation/Translation").default.translate;

let SESSION_LIFETIME = document.querySelector("meta[name='SESSION_LIFETIME']");
let session_ms = 0;

if (SESSION_LIFETIME) {
    session_ms = SESSION_LIFETIME.getAttribute("content");
    Vue.prototype.$session_ms = session_ms * 60000;
}

router.beforeEach((to, _from, next) => {
    // const isLoggedIn = store.state.login.isLoggedIn;
    // const requiresAuth = to.meta.requiesAuth;
    // if (requiresAuth && isLoggedIn) {
    next();
    // }
});
import { gvar } from "./gVar";

const app = new Vue({
    el: "#app",
    router,
    store,
    mounted() {
        if (
            this.$route.path == "" ||
            this.$route.path == "/" ||
            this.$route.path == "/home" ||
            this.$route.path == "/dashboard" ||
            this.$route.path == "/js/vue-select.js.map"
        ) {
            this.$router.replace("/location-info");
        }
        // sub-menu open
        document.addEventListener("mouseup", function (e) {
            let container = document.getElementsByClassName("menu-open");
            let treeview = document.getElementsByClassName("nav-treeview");
            let treeviewID = document.getElementById("con_nav_treeview");
            if (container.length != 0) {
                if (!container[0].contains(e.target)) {
                    treeview[0].style.display = "none";
                    treeviewID.classList.remove("menu-open");
                }
            }
        });
        //end sub-menu open
    },
    data() {
        return {};
    },
    watch: {
        $route(to, from) { }
    },
    methods: {
        ...mapActions([""]),

        inactivityTime() {
            window.onload = this.resetTimer;
            window.onmousemove = this.resetTimer;
            window.onmousedown = this.resetTimer;
            window.ontouchstart = this.resetTimer;
            window.onclick = this.resetTimer;
            window.onscroll = this.resetTimer;
            window.onkeypress = this.resetTimer;
        },
        logout() {
            let logout_form = document.getElementById("logout-form");
            localStorage.clear();
            if (logout_form) {
                logout_form.submit();
            }
        },

        resetTimer() {
            clearTimeout(this.interval);
            this.interval = setTimeout(this.countDown, this.$session_ms); //milliseconds
        },
        countDown() {
            clearTimeout(this.interval);
            this.logout();
        }
    }
});
