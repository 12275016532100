export const gvar = {
    superadmin: 1,
    admin: 1,
    customer: 2,
    user_role_id: 1,
    admin_password_status: {
        DEFAULT_ADMIN_PASSWORD: 1,
        DEFAULT_ADMIN_PASSWORD_CHANGE: 2
    },
   
};


//this file must be the same with gvar.php
