import axios from "axios";

const state = {
    province_selector: [],
    provinceAll_selector: [],
    districts_selector: [],
    sub_districts_selector: [],
};

const getters = {
    province_selector: (state) => state.province_selector,
    provinceAll_selector: (state) => state.provinceAll_selector,
    districts_selector: (state) => state.districts_selector,
    sub_districts_selector: (state) => state.sub_districts_selector,
};

const actions = {
    async getProvinceAllSelector({ commit }) {
        const response = await axios.get(`/api/province-selector`);
        commit("set_provinceAll_selector", response.data);
        return response.data;
    },
    async getZipCodeSelector({ commit }, zipcode) {
        const response = await axios.get(`/api/address/${zipcode}`);
        commit("set_province_selector", response.data.provinces);
        commit("set_districts_selector", response.data.districts);
        commit("set_sub_districts_selector", response.data.subdistricts);
    },
    async getDistrictsSelector({ commit }, prov_id) {
        const response = await axios.get(`/api/district-selector/${prov_id}`);

        commit("set_districts_selector", response.data);
    },
    async getSubDistrictsSelector({ commit }, dist_id) {
        const response = await axios.get(
            `/api/sub-district-selector/${dist_id}`
        );

        commit("set_sub_districts_selector", response.data);
    },
    async createProvince({ commit }, body) {
        return await axios
            .post("/api/provinces/create", body)
            .then((response) => {
                if (response.data.success) {
                    commit("newProvince", response.data.new_data);
                }
                return response.data;
            });
    },
    async returnOldDataProvince({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/provinces/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataProvince",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async createDistric({ commit }, body) {
        return await axios
            .post("/api/districts/create", body)
            .then((response) => {
                if (response.data.success) {
                    commit("newDistricts", response.data.new_data);
                }
                return response.data;
            });
    },
    async createSubDistric({ commit }, body) {
        return await axios
            .post("/api/subdistrict/create", body)
            .then((response) => {
                if (response.data.success) {
                    commit("newSubDistricts", response.data.new_data);
                }
                return response.data;
            });
    },
    async updateProvince({ commit }, body) {
        return await axios
            .post("/api/provinces/update/" + body.id, body)
            .then((response) => {
                if (response.data.success) {
                    commit("updateProvince", response.data.new_data);
                }
                return response.data;
            });
    },
    async updateDistric({ commit }, body) {
        return await axios
            .post("/api/districts/update/" + body.id, body)
            .then((response) => {
                if (response.data.success) {
                    commit("updateDistricts", response.data.new_data);
                }
                return response.data;
            });
    },

    async returnOldDataDistric({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/districts/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataDistric",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async updateSubDistric({ commit }, body) {
        return await axios
            .post("/api/subdistrict/update/" + body.id, body)
            .then((response) => {
                console.log("store :: ", response);
                if (response.data.success) {
                    commit("updateSubDistricts", response.data.new_data);
                }
                return response.data;
            });
    },
    async returnOldDataSubDistric({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/subdistrict/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataSubDistric",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async deleteProvince({ commit }, body) {
        return await axios
            .post("/api/provinces/delete/" + body.id)
            .then((response) => {
                commit("removeProvince", body.id);
                return response.data;
            });
    },
    async deleteDistric({ commit }, body) {
        return await axios
            .post("/api/districts/delete/" + body.id)
            .then((response) => {
                commit("removeDistricts", body.id);
                return response.data;
            });
    },
    async deleteSubDistric({ commit }, body) {
        return await axios
            .post("/api/subdistrict/delete/" + body.id)
            .then((response) => {
                commit("removeSubDistricts", body.id);
                return response.data;
            });
    },
};

const mutations = {
    set_province_selector: (state, payload) =>
        (state.province_selector = payload),
    set_provinceAll_selector: (state, payload) =>
        (state.provinceAll_selector = payload),
    set_districts_selector: (state, payload) =>
        (state.districts_selector = payload),
    set_sub_districts_selector: (state, payload) =>
        (state.sub_districts_selector = payload),
    newProvince: (state, payload) =>
        state.provinceAll_selector.unshift(payload),
    addReturnOldDataProvince: (state, payload) =>
        state.provinceAll_selector.unshift(payload),
    newDistricts: (state, payload) => state.districts_selector.unshift(payload),

    addReturnOldDataDistric: (state, payload) =>
        state.districts_selector.unshift(payload),
    newSubDistricts: (state, payload) =>
        state.sub_districts_selector.unshift(payload),
    removeProvince: (state, id) =>
        (state.provinceAll_selector = state.provinceAll_selector.filter(
            (province) => province.id !== id
        )),
    removeDistricts: (state, id) =>
        (state.districts_selector = state.districts_selector.filter(
            (districts) => districts.id !== id
        )),
    removeSubDistricts: (state, id) =>
        (state.sub_districts_selector = state.sub_districts_selector.filter(
            (sub_districts) => sub_districts.id !== id
        )),
    updateProvince(state, payload) {
        state.provinceAll_selector = state.provinceAll_selector.map(
            (province) => {
                if (province.id === payload.id) {
                    return Object.assign({}, province, payload);
                }
                return province;
            }
        );
    },
    updateDistricts(state, payload) {
        state.districts_selector = state.districts_selector.map((districts) => {
            if (districts.id === payload.id) {
                return Object.assign({}, districts, payload);
            }
            return districts;
        });
    },
    updateSubDistricts(state, payload) {
        state.sub_districts_selector = state.sub_districts_selector.map(
            (sub_districts) => {
                if (sub_districts.id === payload.id) {
                    return Object.assign({}, sub_districts, payload);
                }
                return sub_districts;
            }
        );
    },
    addReturnOldDataSubDistric: (state, payload) =>
        state.sub_districts_selector.unshift(payload),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
