import axios from "axios";

const state = {
    locations: [],
    location: "",
};

const getters = {
    allLocations: (state) => state.locations,
    location: (state) => state.location,
};
const actions = {
    fetchLocations({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .post(filter.pagiurl, filter)
                .then((response) => {
                    resolve(response);
                    commit("setLocation", response.data.message.data);
                })
                .catch((error) => {
                    reject(error.response.data.errors);
                });
        });
    },
    async addLocation({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/location/create", assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("newLocation", response.data.new_data);
                        // return response.data.new_data
                    }
                })
                .catch((error) => {
                    reject(error);
                    // return error
                });
        });
    },

    updateLocation({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/location/update/" + assign.id, assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("updateLocation", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    returnOldDataLocation({ commit }, assign) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/location/return-old-data", assign)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataLocation",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    deleteLocation({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/location/delete/" + id, {})
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("removeLocation", id);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    setLocation: (state, payload) => (state.locations = payload),
    newLocation: (state, payload) => state.locations.unshift(payload),
    removeLocation: (state, id) =>
        (state.locations = state.locations.filter(
            (account) => account.id !== id
        )),
    updateLocation(state, payload) {
        state.locations = state.locations.map((location) => {
            if (location.id === payload.id) {
                return Object.assign({}, location, payload);
            }
            return location;
        });
    },
    addReturnOldDataLocation: (state, payload) =>
        state.locations.unshift(payload),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
