<template>
  <div>
    <!-- Modal -->
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="ForgetPasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ForgetPasswordModalTitle"
      aria-hidden="true"
      ref="ForgetPasswordModal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h6 style="text-align: center" class="textforget">
              Forgot password
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="sendEmail()">
            <div class="modal-body ">
              <div class="card-body">
                <div class="form-group">
                  <label for="">{{
                    translate("menu2.email")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.email"
                    :class="$errorBorder('email')"
                    autocomplete="off"
                  />
                  <div :class="$errorText('email')">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <div class="modal-footer justify-content-between border-0">
              <button
                type="button"
                class="btn btn-outline-info custom_btn"
                data-dismiss="modal"
              >
                {{ translate("menu.close") }}
              </button>
              <button type="submit" class="btn btn-primary custom_btn">
                {{ translate("menu2.send") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var inputData = {
  email: "",
};

export default {
  components: {},
  mounted() {
    $(this.$refs.ForgetPasswordModal).on("hidden.bs.modal", () => {
      this.$resetForm(this.errors, this.form);
      this.errorMsg = "";
    });
  },
  data() {
    return {
      editMode: false,
      form: { ...inputData },
      errorMsg: "",
      errors: { ...inputData },
      roles: [],
    };
  },

  watch: {},
  methods: {
    openModal() {
      $(this.$refs.ForgetPasswordModal).modal("show");
    },

    sendEmail() {
      $(".loader").show();
      axios
        .post(`/api/forget-password-mail`, this.form)
        .then((response) => {
          console.log(response);
          $(".loader").hide();
          if (response.data.success) {
            $(this.$refs.ForgetPasswordModal).modal("hide");
            swal.fire("", response.data.message, "success");
            this.editMode = false;
          } else {
            if (this.$errorMsg(response.data.message)) {
              swal.fire(
                this.translate("menu.error"),
                response.data.message,
                "error"
              );
            }
            this.$validateMsg(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            swal.fire("Hello!", error.response.data.message, "warning");
          }
        });
    },
  },
};
</script>

<style lang="sass">
</style>
