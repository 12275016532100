export default class Basic {
    constructor(userRole) {
        this.userRole = userRole;
    }

    isAllow(value, index) {
        let va = BigInt(value) >> BigInt(index);
        let valu = BigInt(va) & BigInt(1);
        return valu == BigInt(1);
    }
    //user
    isCreateUser() {
        return this.isAllow(this.userRole, 0);
    }
    isViewUsers() {
        return this.isAllow(this.userRole, 1);
    }
    isEditUser() {
        return this.isAllow(this.userRole, 2);
    }
    isDeleteUser() {
        return this.isAllow(this.userRole, 3);
    }
    isBlockUser() {
        return this.isAllow(this.userRole, 4);
    }
    isEditPermission() {
        return this.isAllow(this.userRole, 5);
    }

    //location
    isCreateLocation() {
        return this.isAllow(this.userRole, 6);
    }
    isViewLocations() {
        return this.isAllow(this.userRole, 7);
    }
    isEditLocation() {
        return this.isAllow(this.userRole, 8);
    }
    isDeleteLocation() {
        return this.isAllow(this.userRole, 9);
    }
    //variety
    isCreateVariety() {
        return this.isAllow(this.userRole, 10);
    }
    isViewVarieties() {
        return this.isAllow(this.userRole, 11);
    }
    isEditVariety() {
        return this.isAllow(this.userRole, 12);
    }
    isDeleteVariety() {
        return this.isAllow(this.userRole, 13);
    }
    //master data
    isCreateMasterData() {
        return this.isAllow(this.userRole, 14);
    }
    isViewMasterData() {
        return this.isAllow(this.userRole, 15);
    }
    isEditMasterData() {
        return this.isAllow(this.userRole, 16);
    }
    isDeleteMasterData() {
        return this.isAllow(this.userRole, 17);
    }
    //position
    isCreatePosition() {
        return this.isAllow(this.userRole, 18);
    }
    isViewPosition() {
        return this.isAllow(this.userRole, 19);
    }
    isEditPosition() {
        return this.isAllow(this.userRole, 20);
    }
    isDeletePosition() {
        return this.isAllow(this.userRole, 21);
    }

    //process
    isCreateProcess() {
        return this.isAllow(this.userRole, 22);
    }
    isViewProcess() {
        return this.isAllow(this.userRole, 23);
    }
    isEditProcess() {
        return this.isAllow(this.userRole, 24);
    }
    isDeleteProcess() {
        return this.isAllow(this.userRole, 25);
    }
}
