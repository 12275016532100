<template>
  <div>
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="manageLocationModal"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ mode }} {{ group }}
            </h5>
            <div class="text-detail-alert" v-show="mode == 'Delete'">
              {{
                `${translate("menu.do_you_sure_to_delete")} ${
                  form.name
                } ${translate("menu.remove_form")} ${group} ?`
              }}
            </div>
            <div class="container-input" v-show="mode !== 'Delete'">
              <div class="box-input">
                <label class="title-input is-required">
                  {{ translate("menu.Name") }}
                </label>
                <input
                  type="text"
                  @keyup="checkValue('name')"
                  @blur="checkValue('name')"
                  v-model="form.name"
                />
                <div class="text-validate" style="display: none" id="name">
                  {{
                    `${translate("menu.please_enter")} ${translate(
                      "menu.Name"
                    )}`
                  }}
                </div>
                <div :class="$errorText('name')">
                  {{ errors.name }}
                </div>
              </div>
              <div class="box-input" v-show="group == 'Sub District'">
                <label class="title-input is-required">{{
                  translate("menu.zipcode")
                }}</label>
                <input
                  type="text"
                  @keyup="checkValue('zipcode')"
                  @blur="checkValue('zipcode')"
                  v-model="form.zipcode"
                />
                <div class="text-validate" style="display: none" id="zipcode">
                  {{
                    `${translate("menu.please_enter")} ${translate(
                      "menu.zipcode"
                    )}`
                  }}
                </div>
              </div>
            </div>
            <div class="container-btn">
              <div class="box-btn">
                <div class="btn err" @click="closeModal">
                  {{ translate("menu.cancel") }}
                </div>
              </div>
              <div class="box-btn">
                <div class="btn succ" @click="confirm">
                  {{ translate("menu.confirm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      mode: "",
      fomat: "",
      form: {},
      errors: { name: "" },
      group: "",
    };
  },
  mounted() {
    Fire.$on("validate", (data) => {
      this.validate(data);
    });
  },

  watch: {},

  methods: {
    ...mapActions([]),
    checkValue(nameElement) {
      let element = document.getElementById(nameElement);
      if (this.form[nameElement] == undefined) {
        this.form[nameElement] = "";
        element.style.display = "block";
      }
      if (this.form[nameElement] == "") {
        element.style.display = "block";
      }
      if (this.form[nameElement] != "") {
        element.style.display = "none";
      }
    },
    async openModal(data) {
      this.form = {};
      this.errors.name = "";
      this.mode = data.action;
      this.group = data.group;
      if (data.name) {
        this.form["name"] = data.name;
      }
      if (data.zipcode) {
        this.form["zipcode"] = data.zipcode;
      }
      $(this.$refs.manageLocationModal).modal("show");
    },

    confirm() {
      let savedata = true;
      if (!this.form.name) {
        savedata = false;
        this.checkValue("name");
      } else {
        this.checkValue("name");
        savedata = true;
      }

      if (this.group == "Sub District") {
        if (!this.form.zipcode) {
          savedata = false;
          this.checkValue("zipcode");
        } else {
          this.checkValue("zipcode");
          savedata = true;
        }
      }

      if (savedata) {
        this.$emit("actionLocationModal", {
          action: this.mode,
          group: this.group,
          name: this.form.name,
          zipcode: this.form.zipcode,
        });
        // if (this.mode == "ADD") {
        // this.addUser(this.form).then((res) => {
        // 	toast.fire({
        // 		position: "top-end",
        // 		icon: "success",
        // 		title: this.translate("menu.success"),
        // 	});
        // 	this.closeModal()
        // })
        // } else if (this.mode == "EDIT") {
        // this.updateUser(this.form).then((res) => {
        // 	toast.fire({
        // 		position: "top-end",
        // 		icon: "success",
        // 		title: this.translate("menu.success"),
        // 	});
        // this.closeModal();
        // })
        // }
      }
    },

    closeModal() {
      $(this.$refs.manageLocationModal).modal("hide");
    },
    validate(data) {
      this.$validateMsg(data.message);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ebebeb;
  padding: 38px 32px;
  .modal-header {
    display: block;
    position: relative;
    border: none;
    padding: 0px;

    .modal-title {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #000;
    }
    .text-detail-alert {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
    .container-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        &.w-haft {
          width: 48%;
        }
        .title-input {
          &.is-required {
            &::after {
              margin-left: 1px;
              color: #ff0000;
              content: "*";
            }
          }
        }
        input {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          :-webkit-autofill {
            color: #fff !important;
          }
          &:focus {
            outline: none;
          }
        }
        select {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
        }
      }
    }
    .btn-close-modal {
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      background: none;
      margin: 16px;

      i {
        font-size: 20px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: var(--bg-content-default);
      margin: 8px 0;
    }
    .box-name-lotto {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .container-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .box-btn {
      margin: 8px 18px;

      .btn {
        padding: 8px 12px;
        box-shadow: 0px 4px 4px #00000040;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;

        &.succ {
          background: #617c36;

          &:hover {
            transition-duration: 500ms;
            background: #394920;
          }
        }
        &.err {
          background: #e03737;

          &:hover {
            transition-duration: 500ms;
            background: #972323;
          }
        }
      }
    }
  }
}
.text-validate {
  color: #ff0000;
  font-size: 12px;
  margin-left: 4px;

  &.d-none {
    display: none;
  }
}
</style>
