<template>
  <div>
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="modalAdminInfo"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <form @submit.prevent="editMode ? updateAdmin() : addAdmin()">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{
                  editMode
                    ? translate("menu.edit_admin_information")
                    : translate("menu.add_admin_information")
                }}
              </h5>
              <div class="container-input">
                <div class="box-input w-haft">
                  <label class="title-input is-required">
                    {{ translate("menu.first_name") }}</label
                  >
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    v-model.trim="form.firstname"
                    :class="$errorBorder('firstname')"
                  />
                  <div :class="$errorText('firstname')">
                    {{ errors.firstname }}
                  </div>
                </div>
                <div class="box-input w-haft">
                  <label class="title-input is-required">{{
                    translate("menu.last_name")
                  }}</label>
                  <input
                    type="text"
                    name="name"
                    v-model.trim="form.lastname"
                    :class="$errorBorder('lastname')"
                  />
                  <div :class="$errorText('lastname')">
                    {{ errors.lastname }}
                  </div>
                </div>
                <div class="box-input">
                  <label class="title-input is-required">{{
                    translate("menu.phone_no")
                  }}</label>
                  <input
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="number"
                    maxlength="10"
                    name="name"
                    v-model.trim="form.phone"
                    :class="$errorBorder('phone')"
                  />
                  <div :class="$errorText('phone')">
                    {{ errors.phone }}
                  </div>
                </div>
                <div class="box-input">
                  <label class="title-input is-required">{{
                    translate("menu.email")
                  }}</label>
                  <input
                    type="text"
                    name="name"
                    v-model.trim="form.email"
                    :class="$errorBorder('email')"
                  />
                  <div :class="$errorText('email')">
                    {{ errors.email }}
                  </div>
                </div>
                <div class="box-input">
                  <label class="title-input is-required">{{
                    translate("menu.position")
                  }}</label>
                  <select
                    class="form-control cursor_pointer"
                    v-model="form.posi_id"
                    :style="
                      form.posi_id == undefined
                        ? 'color: #7c7c7c'
                        : 'color: #000'
                    "
                  >
                    <option
                      :value="undefined"
                      disabled
                      style="background: #e5e5e5"
                    >
                      {{ translate("menu.select") }}
                    </option>
                    <option
                      v-for="role in positions"
                      :value="role.id"
                      :key="role.id"
                      style="color: #000"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                  <div :class="$errorText('posi_id')">
                    {{ errors.posi_id }}
                  </div>
                </div>
                <div class="box-input">
                  <label class="title-input is-required">{{
                    translate("menu.role")
                  }}</label>
                  <select
                    class="form-control cursor_pointer"
                    v-model="form.role_id"
                    :style="
                      form.role_id == undefined
                        ? 'color: #7c7c7c'
                        : 'color: #000'
                    "
                  >
                    <option
                      :value="undefined"
                      disabled
                      style="background: #e5e5e5"
                    >
                      {{ translate("menu.select") }}
                    </option>
                    <option
                      v-for="role in roles"
                      :value="role.id"
                      :key="role.id"
                      style="color: #000"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                  <div :class="$errorText('role_id')">
                    {{ errors.role_id }}
                  </div>
                </div>
              </div>

              <div class="container-btn">
                <div class="box-btn">
                  <button type="button" class="btn err" data-dismiss="modal">
                    {{ translate("menu.cancel") }}
                  </button>
                </div>
                <div class="box-btn">
                  <button type="submit" class="btn succ">
                    {{ translate("menu.confirm") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var inputData = {
  id: "",
  role_id: "",
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  posi_id: "",
  position: "",
};
import { mapActions } from "vuex";
export default {
  props: ["position", "role"],
  data() {
    return {
      mode: "",
      title: "",
      fomat: "",
      dataPass: "",
      dataConfirmPass: "",
      roles: this.role,
      positions: this.position,
      validateConfirmPass: "",

      editMode: false,
      form: { ...inputData },
      errorMsg: "",
      errors: { ...inputData },
    };
  },
  mounted() {
    $(this.$refs.modalAdminInfo).on("hidden.bs.modal", () => {
      this.$cust_resetForm(this.errors, this.form);
      this.errorMsg = "";
      this.editMode = false;
    });
  },

  watch: {
    position() {
      this.positions = this.position;
    },
    role() {
      this.roles = this.role;
    },
  },

  methods: {
    ...mapActions(["updateUser", "addUser", "returnOldDataUser"]),

    async openModal(data) {
      if (data) {
        this.$setFormToEdit(data, this.form);
        this.editMode = true;
      } else {
        this.editMode = false;
      }
      $(this.$refs.modalAdminInfo).modal("show");
    },

    updateAdmin() {
      let posi = this.form.posi_id;
      this.form.position = this.positions
        .filter((x) => x.id == posi)
        .map((x) => x.name)[0];

      let CUpath = this.updateUser(this.form);
      this.CU(CUpath);
    },
    addAdmin() {
      let posi = this.form.posi_id;
      this.form.position = this.positions
        .filter((x) => x.id == posi)
        .map((x) => x.name)[0];

      let CUpath = this.addUser(this.form);
      this.CU(CUpath);
    },
    CU(CUpath) {
      $(".loader").show();
      CUpath.then((response) => {
        $(".loader").hide();
        if (response.data.success) {
          swal.fire(
            this.editMode ? "Updated!" : "Added!",
            response.data.message,
            "success"
          );
          this.editMode = false;
          $(this.$refs.modalAdminInfo).modal("hide");
        } else {
          //****************return deleted data*********************
          //****************end of return deleted data***************
          if (this.$errorMsg(response.data.message)) {
            if (response.data.existed_data) {
              this.returnOldData(
                response.data.existed_data,
                response.data.message
              );
            } else {
              swal.fire(
                this.translate("menu2.error"),
                response.data.message,
                "error"
              );
            }
          }
          this.$validateMsg(response.data.message);
        }
      }).catch((error) => {
        if (error.response) {
          swal.fire(
            this.translate("menu2.error"),
            error.response.data.message,
            "error"
          );
        }
      });
    },
    returnOldData(data, message) {
      swal
        .fire({
          title: "",
          // text: `DO YOU REALLY WANT TO DELETE ${data.name} FROM POSITION INFORMATION`,
          text: message,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.translate("menu.cancel"),
          confirmButtonText: this.translate("menu2.yes_return"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.returnOldDataUser(data).then((res) => {
              if (res.data.success) {
                $(this.$refs.modalAdminInfo).modal("hide");
                swal.fire("", res.data.message, "success");
              }
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ebebeb;
  padding: 38px 32px;
  .modal-header {
    display: block;
    position: relative;
    border: none;
    padding: 0px;

    .modal-title {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #000;
    }
    .container-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        &.w-haft {
          width: 48%;
        }
        .title-input {
          &.is-required {
            &::after {
              margin-left: 1px;
              color: #ff0000;
              content: "*";
            }
          }
        }
        input {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          :-webkit-autofill {
            color: #fff !important;
          }
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: auto;
          }
        }
        select {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          &:hover {
            cursor: auto;
          }
        }
      }
    }
    .btn-close-modal {
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      background: none;
      margin: 16px;

      i {
        font-size: 20px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: var(--bg-content-default);
      margin: 8px 0;
    }
    .box-name-lotto {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .container-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .box-btn {
      margin: 8px 18px;

      .btn {
        padding: 8px 12px;
        box-shadow: 0px 4px 4px #00000040;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;

        &.succ {
          background: #92b558;

          &:hover {
            transition-duration: 500ms;
            background: #394920;
          }
        }
        &.err {
          background: #e03737;

          &:hover {
            transition-duration: 500ms;
            background: #972323;
          }
        }
      }
    }
  }
}
.text-validate {
  color: #ff0000;
  font-size: 12px;
  margin-left: 4px;

  &.d-none {
    display: none;
  }
}
</style>
