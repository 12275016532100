<template>
  <div>
    <section>
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-5">
            <h1>
              <!-- {{ translate('menu.location') }} -->
              Location
            </h1>
          </div>
          <div class="col-sm-7 d-flex justify-content-end align-items-center">
            <div class="card-tools">
              <div class="input-group">
                <form @submit.prevent="searchKey()" class="contain_form_search">
                  <div class="year">
                    <vSelect class="form-control" label="name" :options="allYear" v-model="yearData"
                      :placeholder="translate('menu2.year')">
                    </vSelect>
                  </div>
                  <div class="province">
                    <vSelect class="form-control" label="name" :options="allProvice" v-model="province"
                      :placeholder="translate('menu2.province')">
                    </vSelect>
                  </div>
                  <div>
                    <button type="submit" class="btn btn-success btn_serach_key">
                      <i class="fas fa-search "></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-end">
              <div class="title">
                <!-- <button class="btn btn-loc" @click="openModal()">
                  <i class="fas fa-plus"></i>
                  {{ translate("menu.Add") }}
                </button> -->
                <!-- <input
                    type="text"
                    name="table_search"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    :placeholder="translate('menu.search_name')"
                    v-model="search_name"
                  /> -->
                <input type="text" name="table_search" class="form-control" aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default" placeholder="Search name"
                  v-model="search_name" />
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped sortTable">
                <thead>
                  <tr>
                    <td width="5%">
                      <label>{{ translate("menu.No") }}</label>
                    </td>
                    <td width="20%">
                      <label>{{ translate("menu.Name") }}</label>
                    </td>
                    <!-- <td width="20%">
                      <label>{{ translate("menu.Image") }}</label>
                    </td> -->
                    <td width="10%">
                      <label>{{ translate("menu.Location") }}</label>
                    </td>
                    <td width="20%">
                      <label>{{ translate("menu.Address") }}</label>
                    </td>
                    <!-- <td width="20%">
                      <label>{{ translate("menu.MASL") }}</label>
                    </td> -->
                    <td width="5%">
                      <label></label>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in sortedItems()" :key="index" @click="openLocation(data)" class="cursor_p">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ data.name }}
                    </td>

                    <td>
                      {{ data.location }}
                    </td>
                    <td>
                      {{ data.postcode }}, {{ data.province }},
                      {{ data.district }},
                      {{ data.subdistrict }}
                    </td>

                    <td style="display: flex">
                      <button class="btn btn-action">
                        <i class="fas fa-search btn-edit"></i>
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  props: [],
  components: { vSelect, DatePicker },
  mounted() {
    let allYearPass = [];
    this.getAllCoffeeSpotYear()
      .then((res) => {
        if (res.data.success) {
          let date = res.data.message;
          date.forEach((element) => {
            let toWord = moment(element.name).format("YYYY");
            toWord.toString();
            allYearPass.push(toWord);
          });
        }
        this.allYear = allYearPass.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
      })
      .catch((error) => { });
    let allProvicePass = [];
    this.getAllCoffeeSpotProvinces()
      .then((res) => {
        if (res.data.success) {
          let date = res.data.message;
          date.forEach((element) => {
            allProvicePass.push(element.name);
          });
        }
        this.allProvice = allProvicePass.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
      })
      .catch((error) => { });

    this.searchKey()
  },
  data() {
    return {
      allProvice: [],
      locationZoomOut: [],
      locationZoomIn: [],
      notYear: 0,
      allYear: [],
      yearData: "",
      province: "",
      filter: {
        year: new Date(),
        province: "",
      },
      errors: {
        year: "",
        province: "",
      },
      coffeeSpot: [],
      coffeeData: [],
      search_name: ''

      // isInputSearch: false,
      // sort: {
      //   key: "",
      //   isAsc: false,
      // },
    };
  },
  computed: mapGetters(["allCoffeeProvinces"]),
  // watch: {
  //   filter: {
  //     deep: true,
  //     immediate: true,
  //     handler(val) {
  //       if (val.search) {
  //         this.isInputSearch = true;
  //       } else {
  //         this.isInputSearch = false;
  //       }
  //     },
  //   },
  // },
  methods: {
    ...mapActions([
      "getAllCoffeeSpot",
      "getAllCoffeeSpotProvinces",
      "getAllCoffeeSpotYear",
    ]),

    sortedItems() {
      return this.coffeeData.filter((data) =>
        data.name.toLowerCase().includes(this.search_name.toLowerCase())
      )
    },

    searchKey() {
      this.filter.year = this.yearData;
      if (this.filter.year == "Invalid date") {
        this.filter.year = "";
        this.notYear = 0;
      } else if (!this.filter.year) {
      } else {
        this.notYear = 1;
      }
      this.filter.province = this.province
        ? (this.filter.province = this.province)
        : (this.filter.province = "");


      this.getAllCoffeeSpot(this.filter)
        .then((res) => {
          if (res.data.success) {
            this.coffeeData = res.data.message
          }
        })
        .catch((error) => { });
    },
    openLocation(data) {
      let toStr;
      if (this.notYear == 1) {
        toStr = moment(data.scored_date).format("YYYY");
        toStr.toString();
      }
      let url = `/variety-info/${data.id}/${data.name}/${toStr}/undefined`
      console.log('url : ', url);
      this.$router.push(url)

    }



  },
};
</script>

<style lang="scss" scoped>
.btn-action {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 3px;

  .btn-edit {
    color: green;
  }

  .btn-delete {
    color: red;
  }
}

.title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-loc {
  height: 40px;
  text-align: center;
  background: #55b2be;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  color: white;
  font-size: 16px;

  & i {
    margin-right: 9px;
    font-size: 20px;
  }
}

.image-thum-nail {
  max-width: 250px;
  max-height: 300px;
  border-radius: 10px;
}

.contain_form_search {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  .province {
    width: 13rem;
  }

  .year {
    width: 8rem;
  }
}

.cursor_p {
  cursor: pointer;
}
</style>
