<template>
  <div>
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="EvaluationModal"
      style="overflow-y: scroll"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{
                editMode
                  ? translate("menu.edit_coffeee_varity_scores")
                  : translate("menu.add_coffeee_varity_scores")
              }}
            </h5>
            <div v-for="(scoreCategory, idx) in scoreCategories" :key="idx">
              <div class="title-category">{{ scoreCategory.name }}</div>
              <div class="container-input">
                <div
                  class="box-input"
                  v-for="(scoreName, index) in scoreCategory.score_names"
                  :key="index"
                >
                  <div :for="`sn_id_${scoreName.id}`" class="title-input">
                    {{
                      `${scoreName.name} ${
                        scoreName.unit ? scoreName.unit : ""
                      }`
                    }}
                  </div>
                  <!-- @keyup="checkValue(`sn_id_${scoreName.id}`, scoreName.id-1)"
                    @blur="checkValue(`sn_id_${scoreName.id}`, scoreName.id-1)"  -->
                  <input
                    v-if="form[findDataInForm(scoreName.id)]"
                    type="text"
                    :placeholder="scoreName.name"
                    v-model="form[findDataInForm(scoreName.id)].score"
                    :oninput="rePalceInputScore(findDataInForm(scoreName.id))"
                    @blur="checkFormat(findDataInForm(scoreName.id))"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="saveData()" class="btn btn-primary save">
                {{ translate("menu2.save") }}
              </button>
              <button
                type="button"
                class="btn btn-outline-info cancel"
                data-dismiss="modal"
              >
                {{ translate("menu2.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    $(this.$refs.EvaluationModal).on("hidden.bs.modal", () => {
      this.form = this.form.map((res) => {
        return {
          eval_id: "",
          scmn_id: res.scmn_id,
          score: null,
        };
      });
    });
  },
  data() {
    return {
      editMode: false,
      form: [],
      evaluationData: {},
    };
  },
  computed: {
    ...mapGetters(["scoreCategories"]),
  },
  watch: {
    scoreCategories() {
      this.scoreCategories.forEach((items) => {
        items.score_names.forEach((res) => {
          this.form.push({
            eval_id: "",
            scmn_id: res.id,
            score: null,
          });
        });
      });
    },
  },
  methods: {
    ...mapActions(["updateEvaluation", "createScore", "getScoreByEvalId"]),
    findDataInForm(id) {
      return this.form.findIndex((item) => id == item.scmn_id);
    },
    rePalceInputScore(idx) {
    //   let number = this.form[idx].score;
    //   if (number == "" || number == null) {
    //     number = null;
    //   } else {
    //     let dataNumber = number.split(".");
    //     if (dataNumber.length > 2) {
    //       dataNumber.splice(2, 1);
    //       number = dataNumber.join(".");
    //     } else {
    //       if (dataNumber.length > 1) {
    //         dataNumber[0] = dataNumber[0].slice(0, 3);
    //         dataNumber[1] = dataNumber[1].slice(0, 2);
    //         if (parseInt(dataNumber[0]) >= 100) {
    //           dataNumber[0] = "100";
    //           dataNumber[1] = "00";
    //         }
    //         number = dataNumber.join(".");
    //       } else {
    //         if (dataNumber[0].length > 2) {
    //           dataNumber[0] = dataNumber[0].slice(0, 3);
    //           if (parseInt(dataNumber[0]) > 100) {
    //             dataNumber[0] = "100";
    //           }
    //           number = dataNumber.join(".");
    //         }
    //       }
    //     }
    //   }
    //   this.form[idx].score = number;
    },
    checkFormat(idx) {
      let number = this.form[idx].score;
      if (number == "" || number == null) {
        number = null;
      } else {
        let dataNumber = number.split(".");
        if (dataNumber.length > 1) {
          if (dataNumber[1].length == 1) {
            dataNumber[1] = dataNumber[1] + "0";
          }
        } else {
          dataNumber[1] = "00";
        }
        number = dataNumber.join(".");
      }
      this.form[idx].score = number;
    },
    saveData(data) {
      this.form = this.form.map((element) => {
        return {
          ...element,
          eval_id: this.evaluationData.id,
          score: element.score == "" ? null : element.score,
        };
      });
      this.createScore({ data: this.form })
        .then((res) => {
          toast.fire({
            position: "top-end",
            icon: "success",
            title: res.data.message,
          });
          this.closeModal();
        })
        .catch((res) => {
          toast.fire({
            position: "top-end",
            icon: "error",
            title: this.translate("menu.fail"),
          });
        });
    },
    openModal(data) {
      this.evaluationData = { ...data };
      this.getScoreByEvalId(data).then((res) => {
        if (res.data.length > 0) {
          this.editMode = true;
          res.data.forEach((item) => {
            this.form.forEach((element, idx) => {
              if (element.scmn_id == item.scmn_id) {
                this.form[idx].score = item.score;
              }
            });
          });
        } else {
          this.editMode = false;
        }
      });
      $(this.$refs.EvaluationModal).modal("show");
    },
    closeModal() {
      $(this.$refs.EvaluationModal).modal("hide");
    },

    checkValue(nameElement, idx) {
      let element = document.getElementById(nameElement);
      if (this.form[idx] == undefined || this.form[nameElement] == null) {
        element.style.display = "block";
      }
      if (this.form[idx] == "") {
        element.style.display = "block";
      }
      if (this.form[idx] != "") {
        element.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ebebeb;
  padding: 38px 32px;
  .modal-header {
    display: block;
    position: relative;
    border: none;
    padding: 0px;

    .modal-title {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #8c8c8c;
    }
    .title-category {
      margin-top: 18px;
      color: #2c2c2c;
      font-weight: 700;
      font-size: 20px;
    }
    .container-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      .box-input {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
        &.center {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .title-input {
          color: #2c2c2c;
          font-weight: 400;
          font-size: 15px;
          margin-bottom: 2px;
          &.is-required {
            &::after {
              margin-left: 1px;
              color: #ff0000;
              content: "*";
            }
          }
        }
        input {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          :-webkit-autofill {
            color: #fff !important;
          }
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: text;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        img {
          z-index: 2;
          pointer-events: none;
          border-radius: 5px;
        }
        .addImg {
          z-index: 1;
          margin-top: 23px;
          position: absolute;
          content: none;
          width: 140px;
          height: 140px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          &:hover {
            transition-duration: 500ms;
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
          }
        }
        textarea {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          width: 100%;
          height: 96px;
        }
        select {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          &:hover {
            cursor: text;
          }
        }
      }
    }
    .modal-footer {
      display: flex;
      flex-direction: column;
      border-top: none;

      .btn {
        width: 298px;
        border-radius: 20px;
        font-size: 20px;

        &.save_next {
          background: #617c36;
          color: #fff;
          &:hover {
            transition-duration: 500ms;
            background: #415324;
          }
        }
        &.save {
          color: #617c36;
          border: 1px solid #617c36;
          background: #617c3600;
          &:hover {
            transition-duration: 500ms;
            background: rgba(97, 124, 54, 0.2);
          }
        }
        &.cancel {
          border: none;
          color: #000;
          &:hover {
            transition-duration: 500ms;
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .btn-close-modal {
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      background: none;
      margin: 16px;

      i {
        font-size: 20px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: var(--bg-content-default);
      margin: 8px 0;
    }
    .box-name-lotto {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .container-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .box-btn {
      margin: 8px 18px;

      .btn {
        padding: 8px 12px;
        box-shadow: 0px 4px 4px #00000040;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;

        &.succ {
          background: #92b558;

          &:hover {
            transition-duration: 500ms;
            background: #394920;
          }
        }
        &.err {
          background: #e03737;

          &:hover {
            transition-duration: 500ms;
            background: #972323;
          }
        }
      }
    }
  }
}
.text-validate {
  color: #ff0000;
  font-size: 12px;
  margin-left: 4px;

  &.d-none {
    display: none;
  }
}
</style>
