<template>
  <div>
    <!-- Modal -->
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="permissionModal"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ translate("menu2.edit_permission") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="float-right">
              <label class="chcontainer">
                {{ translate("menu2.choose_all") }}
                <input type="checkbox" id="checkAll" @click="checkAll()" />
                <span class="check"></span>
              </label>
            </div>
          </div>
          <form @submit.prevent="updateRecord()">
            <div class="modal-body">
              <div>
                <div class="row pl-3 pr-3">
                  <div
                    class="col-xl-3 col-lg-4 p-3"
                    v-for="(n, index) in permissions"
                    :key="index"
                  >
                    <div v-for="(data, ind) in n" :key="ind" class="p-1">
                      <label class="chcontainer">
                        <input
                          type="checkbox"
                          :id="'permit' + data.id"
                          :value="data.value"
                          :checked="isTrue(data.value)"
                        />
                        {{ $language == "th" ? data.name_th : data.name_en }}
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <div class="modal-footer border-0 justify-content-between">
              <button
                type="button"
                class="btn btn-outline-info custom_btn"
                data-dismiss="modal"
              >
                {{ translate("menu.close") }}
              </button>
              <button type="submit" class="btn btn-primary custom_btn">
                {{ translate("menu.save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var inputData = {
  id: "",
  permission: "",
};
import debounce from "lodash/debounce";
import { mapActions } from "vuex";
export default {
  props: ["data_pro"],
  mounted() {
    $(this.$refs.permissionModal).on("hidden.bs.modal", () => {
      this.$resetForm(this.errors, this.form);
    });
  },
  data() {
    return {
      form: { ...inputData },
      errors: { ...inputData },
      type: 1, //first bit of binary
      permissions: [],
      userData: {},
    };
  },

  watch: {
    data_pro(newVal) {
      if (newVal) {
        this.form.val = newVal;
      }
    },
  },
  methods: {
    ...mapActions(["updateUser"]),

    isTrue(num) {
      let cal = num;
      if (this.form.permission) {
        let va = BigInt(this.form.permission) & BigInt(cal);
        return va == cal;
      }
    },

    checkAll() {
      let checkAll = document.getElementById("checkAll");
      if (checkAll.checked) {
        for (let i = 0; i < this.permissions.length; i++) {
          for (let j = 0; j < this.permissions[i].length; j++) {
            let chpermit = document.getElementById(
              "permit" + this.permissions[i][j].id
            );
            chpermit.checked = true;
          }
        }
      } else {
        for (let i = 0; i < this.permissions.length; i++) {
          for (let j = 0; j < this.permissions[i].length; j++) {
            let chpermit = document.getElementById(
              "permit" + this.permissions[i][j].id
            );
            chpermit.checked = false;
          }
        }
      }
    },

    openModal(data) {
      this.form.id = data.id;
      this.form.permission = data.permission;
      this.getData();
    },
    getData() {
      axios
        .get("/api/permissions")
        .then((response) => {
          this.permissions = response.data;

          $(this.$refs.permissionModal).modal("show");
        })
        .catch(() => {
          if (error.response) {
            swal.fire("Hello!", error.response.data.message, "warning");
          }
        });
    },

    updateRecord() {
      let datas = this.permissions;
      let sum = 0;
      for (let i = 0; i < datas.length; i++) {
        for (let j = 0; j < datas[i].length; j++) {
          let chele = document.getElementById("permit" + datas[i][j].id);
          if (chele && chele.checked) {
            sum += parseInt(chele.value);
          }
        }
      }
      this.form.permission = sum;
      axios
        .post("/api/user/update-permission", this.form)
        .then((res) => {
          if (res.data.success) {
            toast.fire({
              position: "top-end",
              icon: "success",
              title: this.translate("menu.success"),
              text: res.data.message,
            });

            Fire.$emit("update_permission");
          } else {
            toast.fire({
              position: "top-end",
              icon: "error",
              title: this.translate("menu.error"),
              text: res.data.message,
            });
          }

          $(this.$refs.permissionModal).modal("hide");
        })
        .catch((error) => {
          if (error.response) {
            swal.fire("Hello!", error.response.data.message, "warning");
          }
        });

      // this.form.val = sum + parseInt(this.type);
      // Fire.$emit("save_allow_withdraw_emit", this.form.val);
      // $(this.$refs.permissionModal).modal("hide");
    },
  },
};
</script>

<style lang="sass">
</style>
