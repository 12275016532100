
<template>
  <div style="padding-right: 15px; padding-left: 15px">
    <h1>{{ translate("menu.manage-admin-account") }}</h1>
    <div class="search-add">
      <searchBox @search="filterText" />
      <div class="btn-add" @click="openModalAdminInfo()">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
        <span class="text"
          >{{ translate("menu.add") }} {{ translate("menu.admin") }}</span
        >
      </div>
    </div>
    <div class="overflow-reponsive">
      <table class="table table-bordered table-hover table-striped sortTable">
        <thead>
          <tr>
            <th width="5%">{{ translate("menu.No") }}</th>
            <th>{{ translate("menu.full_name") }}</th>
            <th>{{ translate("menu.phone_number") }}</th>
            <th>{{ translate("menu.email") }}</th>
            <th>{{ translate("menu.position") }}</th>
            <th class="text-center" width="10%">
              {{ translate("menu.edit") }}/{{ translate("menu.delete") }}
            </th>
            <th v-if="$permission.isEditPermission()">
              {{ translate("menu.access_rights") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in allUsers" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ `${item.firstname} ${item.lastname}` }}</td>
            <td>{{ `${item.phone}` }}</td>
            <td>{{ `${item.email}` }}</td>
            <td>{{ `${item.position}` }}</td>
            <td>
              <div class="actions_block_banka_acc">
                <div
                  class="
                    col
                    p-0
                    m-0
                    d-flex
                    justify-content-center
                    align-items-center
                    write_block
                    link_to
                    bg_action_bank_acc
                    min_width_bank_acc
                  "
                  @click="openModalAdminInfo(item)"
                >
                  <i class="fas fa-edit text-primary cursor_pointer"></i>
                </div>
                <div
                  class="
                    col
                    p-0
                    m-0
                    d-flex
                    justify-content-center
                    align-items-center
                    link_to
                    bg_action_bank_acc
                    min_width_bank_acc
                  "
                  @click="
                    deleteUserById(
                      item.id,
                      `${item.firstname} ${item.lastname}`
                    )
                  "
                  :id="'delete' + idx"
                >
                  <i class="fas fa-trash-alt text-danger cursor_pointer"></i>
                </div>
              </div>
            </td>
            <td v-if="$permission.isEditPermission()">
              <button
                class="btn btn-sm btn-outline-primary"
                type="button"
                @click="openPermissionModal(item)"
              >
                {{ translate("menu.access_rights") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationStep
      :defaultSizeList="filter.listNum"
      :totalList="totalList"
      @page="getUseBySize"
    />
    <ModalAdminInfo
      ref="modalAdminInfo"
      :position="dataPosition"
      :role="dataRole"
    />
    <PermissionModal
      ref="permissionModal"
      :data_pro="allow_withdraw_commission"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import searchBox from "../components/searchBox";
import ModalAdminInfo from "../components/modalAdminInfo";
import PaginationStep from "../components/paginationStep";
import PermissionModal from "../components/permissionModal";

export default {
  props: [],
  components: { searchBox, ModalAdminInfo, PaginationStep, PermissionModal },
  mounted() {
    this.getListUser(this.filter);
    this.getRoles().then((res) => {
      this.dataRole = res;
    });
    this.getPositionSelector().then((res) => {
      this.dataPosition = res;
    });
    Fire.$on("update_permission", () => {
      this.getListUser(this.filter);
    });
  },
  data() {
    return {
      items: [
        {
          id: "1",
          email: "6231305013@lamduan.mfu.ac.th",
          password: "12345678",
          firstname: "Nidjaree",
          lastname: "Binchai",
          phone: "0812345679",
          posi_id: 1,
          used: 1,
        },
        {
          id: "2",
          email: "6231305013@lamduan.mfu.ac.th",
          password: "12345678",
          firstname: "Niracha",
          lastname: "Leepreecha",
          phone: "0897654321",
          posi_id: 2,
          used: 1,
        },
      ],
      dataPosition: [],
      dataRole: [],
      totalList: 0,
      filter: {
        listNum: 10,
        search: "",
      },
      allow_withdraw_commission: 0,
    };
  },
  computed: mapGetters(["allUsers"]),
  watch: {},
  methods: {
    ...mapActions([
      "getAllUsernames",
      "deleteUser",
      "getRoles",
      "getPositionSelector",
    ]),
    filterText(data) {
      this.filter.search = data;
      this.getListUser(this.filter);
    },
    getUseBySize(data) {
      this.filter.listNum = data;
      this.getListUser(this.filter);
    },
    openModalAdminInfo(data) {
      let newDate = data ? { ...data } : null;
      this.$refs.modalAdminInfo.openModal(newDate);
    },
    openPermissionModal(data) {
      this.allow_withdraw_commission = data.permission;
      let newDate = data ? { ...data } : null;
      this.$refs.permissionModal.openModal(newDate);
    },
    getListUser(filter) {
      this.getAllUsernames(filter).then((res) => {
        this.totalList = res.total;
      });
    },
    deleteUserById(id, name) {
      swal
        .fire({
          title: this.translate("menu.are_you_sure?"),
          text: `${this.translate(
            "menu.do_you_really_want_to_delete"
          )}${name}${this.translate("menu.form_admin_info")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3490dc",
          cancelButtonColor: "#FF5A5F",
          confirmButtonText: this.translate("menu.confirm"),
          cancelButtonText: this.translate("menu.cancel"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            $(".loader").show();
            this.deleteUser(id)
              .then((res) => {
                if (res.data.success) {
                  $(".loader").hide();
                  toast.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.message,
                  });
                } else {
                  $(".loader").hide();
                  toast.fire({
                    position: "top-end",
                    icon: "error",
                    title: res.data.message,
                  });
                }
              })
              .catch((error) => {
                if (error.response) {
                  swal.fire("Hello!", error.response.data.message, "warning");
                }
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-reponsive {
  overflow-x: auto;
}
.table {
  margin-top: 24px;
  background: #fff;
}
.actions_block_banka_acc {
  height: 29.68px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 7.4199px;
  display: flex;
  justify-content: center;
}
.search-add {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .btn-add {
    height: 40px;
    text-align: center;
    background: #55b2be;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;

    &:hover {
      cursor: pointer;
      transition-duration: 500ms;
      background: #30666d;
    }
    .icon {
      font-size: 18px;
      color: #fff;
    }
    .text {
      margin-left: 8px;
      color: #fff;
    }
  }
}
</style>
