
<template>
  <div class="paddingcoffee">
    <h1>{{ translate("menu.manage_location") }}</h1>
    <div class="container-location">
      <div class="block-content">
        <!-- province -->
        <div class="block province-block" id="province-block">
          <div class="title">
            {{ translate("menu.province") }}
            <div class="search-privince">
              <i class="fas fa-search"></i>
              <input
                type="text"
                :placeholder="translate('menu.search_province')"
                v-model="searchProvince"
              />
            </div>
          </div>
          <div class="block-btn">
            <div
              class="btn-add-data"
              @click="openManageLocationModal('Add', 'Province', null)"
            >
              <span class="subtitle">{{ translate("menu.add_province") }}</span>
              <div class="block-icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="listData">
            <div
              class="card-data"
              v-for="(item, idx) in findMatchString()"
              :key="idx"
              :class="{ active: item.id === idProvince }"
            >
              <div class="name">{{ item.name }}</div>
              <div class="option">
                <div
                  class="block-option edit"
                  @click="
                    (idProvince = item.id),
                      openManageLocationModal('Edit', 'Province', item.name)
                  "
                >
                  <i class="fas fa-edit"></i>
                </div>
                <div
                  class="block-option delete"
                  @click="
                    (idProvince = item.id),
                      openManageLocationModal('Delete', 'Province', item.name)
                  "
                >
                  <i class="fas fa-trash-alt"></i>
                </div>
                <div
                  class="block-option select"
                  @click="
                    getDistrictsSelector(item.id),
                      (idProvince = item.id),
                      (isShowDistric = true),
                      (isShowSubDistric = false)
                  "
                >
                  <span
                    class="
                      d-none
                      d-md-block
                      d-lg-none
                      d-lg-block
                      d-xl-none
                      d-xl-block
                      subtitle
                    "
                    >{{ translate("menu.district") }}</span
                  >
                  <div class="block-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- district -->
        <div
          v-show="isShowDistric"
          class="block distric-block"
          id="distric-block"
        >
          <div class="title center">{{ translate("menu.district") }}</div>
          <div class="block-btn">
            <div
              class="btn-add-data"
              @click="openManageLocationModal('Add', 'District', null)"
            >
              <span class="subtitle">{{ translate("menu.add_district") }}</span>
              <div class="block-icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="listData">
            <div
              class="card-data"
              v-for="(item, idx) in districts_selector"
              :key="idx"
              :class="{ active: item.id === idDistric }"
            >
              <div class="name">{{ item.name }}</div>
              <div class="option">
                <div
                  class="block-option edit"
                  @click="
                    (idDistric = item.id),
                      openManageLocationModal('Edit', 'District', item.name)
                  "
                >
                  <i class="fas fa-edit"></i>
                </div>
                <div
                  class="block-option delete"
                  @click="
                    (idDistric = item.id),
                      openManageLocationModal('Delete', 'District', item.name)
                  "
                >
                  <i class="fas fa-trash-alt"></i>
                </div>
                <div
                  class="block-option select"
                  @click="
                    getSubDistrictsSelector(item.id),
                      (idDistric = item.id),
                      (isShowSubDistric = true)
                  "
                >
                  <span
                    class="
                      d-none
                      d-md-block
                      d-lg-none
                      d-lg-block
                      d-xl-none
                      d-xl-block
                      subtitle
                    "
                    >{{ translate("menu.subdistrict") }}</span
                  >
                  <div class="block-icon">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!isShowDistric" class="block"></div>
        <!-- subdistrict -->
        <div
          v-show="isShowSubDistric"
          class="block sub-distric-block"
          id="sub-distric-block"
        >
          <div class="title center">{{ translate("menu.subdistrict") }}</div>
          <div class="block-btn">
            <div
              class="btn-add-data"
              @click="
                openManageLocationModal('Add', 'Sub District', null, null)
              "
            >
              <span class="subtitle">{{
                translate("menu.add_subdistrict")
              }}</span>
              <div class="block-icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="listData">
            <div
              class="card-data"
              v-for="(item, idx) in sub_districts_selector"
              :key="idx"
            >
              <div class="name">{{ `${item.name} (${item.zipcode})` }}</div>
              <div class="option">
                <div
                  class="block-option edit"
                  @click="
                    (idSubDistric = item.id),
                      openManageLocationModal(
                        'Edit',
                        'Sub District',
                        item.name,
                        item.zipcode
                      )
                  "
                >
                  <i class="fas fa-edit"></i>
                </div>
                <div
                  class="block-option delete"
                  @click="
                    (idSubDistric = item.id),
                      openManageLocationModal(
                        'Delete',
                        'Sub District',
                        item.name,
                        item.zipcode
                      )
                  "
                >
                  <i class="fas fa-trash-alt"></i>
                </div>
                <!-- <div class="block-option select" @click="getSubDistrictsSelector(item.id)">อำเภอ>></div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-show="!isShowSubDistric" class="block"></div>
      </div>
    </div>
    <ManageLocationModal
      ref="manageLocationModal"
      @actionLocationModal="caseModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ManageLocationModal from "./manageLocationModal";
export default {
  props: [],
  components: { ManageLocationModal },
  data() {
    return {
      idProvince: null,
      idDistric: null,
      idSubDistric: null,
      isShowDistric: false,
      isShowSubDistric: false,
      searchProvince: "",
    };
  },

  computed: mapGetters([
    "provinceAll_selector",
    "districts_selector",
    "sub_districts_selector",
  ]),
  watch: {
    idProvince() {
      this.isShowSubDistric = null;
    },
    idDistric() {},
    searchProvince() {
      this.findMatchString();
    },
  },
  mounted() {
    this.getProvinceAllSelector();
  },
  methods: {
    ...mapActions([
      "getProvinceAllSelector",
      "getDistrictsSelector",
      "getSubDistrictsSelector",
      "createProvince",
      "createDistric",
      "createSubDistric",
      "updateProvince",
      "updateDistric",
      "updateSubDistric",
      "deleteProvince",
      "deleteDistric",
      "deleteSubDistric",
      "returnOldDataSubDistric",
      "returnOldDataDistric",
      "returnOldDataProvince"
    ]),
    openManageLocationModal(action, group, name, zipcode) {
      let newDate = {
        action: action,
        group: group,
        name: name,
        zipcode: zipcode,
      };
      this.$refs.manageLocationModal.openModal(newDate);
    },
    findMatchString() {
      return this.provinceAll_selector.filter((item) =>
        item.name.match(this.searchProvince)
      );
    },
    caseModal(data) {
      switch (data.group) {
        case "Province":
          switch (data.action) {
            case "Add":
              this.createProvince({ name: data.name }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.success"),
                    res.message,
                    "success"
                  );
                } else {
                  if (res.message.name) {
                    swal.fire("error", res.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Edit":
              this.updateProvince({
                id: this.idProvince,
                name: data.name,
              }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.success"),
                    res.message,
                    "success"
                  );
                } else {
                  if (this.$errorMsg(res.message)) {
                    if (res.existed_data) {
                      this.returnOldData(
                        res.existed_data,
                        res.message,
                        "Province"
                      );
                    } else {
                      swal.fire(
                        this.translate("menu2.error"),
                        res.message,
                        "error"
                      );
                    }
                  } else if (res.message.name) {
                    swal.fire("error", res.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Delete":
              this.deleteProvince({ id: this.idProvince }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  (this.isShowDistric = false),
                    (this.isShowSubDistric = false),
                    swal.fire(
                      this.translate("menu.deleted"),
                      res.message,
                      "success"
                    );
                } else {
                  swal.fire(
                    this.translate("menu2.error"),
                    res.message,
                    "warning"
                  );
                }
              });
              break;
          }
          break;
        case "District":
          switch (data.action) {
            case "Add":
              this.createDistric({
                name: data.name,
                prov_id: this.idProvince,
              }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.success"),
                    res.message,
                    "success"
                  );
                } else {
                  if (res.message.name) {
                    swal.fire("error", res.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Edit":
              this.updateDistric({
                id: this.idDistric,
                name: data.name,
                prov_id: this.idProvince,
              }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.success"),
                    res.message,
                    "success"
                  );
                } else {
                  if (this.$errorMsg(res.message)) {
                    if (res.existed_data) {
                      this.returnOldData(
                        res.existed_data,
                        res.message,
                        "District"
                      );
                    } else {
                      swal.fire(
                        this.translate("menu2.error"),
                        res.message,
                        "error"
                      );
                    }
                  } else if (res.message.name) {
                    swal.fire("error", res.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Delete":
              this.deleteDistric({ id: this.idDistric }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  (this.isShowSubDistric = false),
                    swal.fire(
                      this.translate("menu.success"),
                      res.message,
                      "success"
                    );
                } else {
                  swal.fire(
                    this.translate("menu2.error"),
                    res.message,
                    "warning"
                  );
                }
              });
              break;
          }
          break;
        case "Sub District":
          switch (data.action) {
            case "Add":
              this.createSubDistric({
                name: data.name,
                dist_id: this.idDistric,
                zipcode: data.zipcode,
              }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.success"),
                    res.message,
                    "success"
                  );
                } else {
                  if (res.message.name) {
                    swal.fire("error", res.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Edit":
              this.updateSubDistric({
                id: this.idSubDistric,
                dist_id: this.idDistric,
                name: data.name,
                zipcode: data.zipcode,
              }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.success"),
                    res.message,
                    "success"
                  );
                } else {
                  if (this.$errorMsg(res.message)) {
                    if (res.existed_data) {
                      this.returnOldData(
                        res.existed_data,
                        res.message,
                        "SubDistrict"
                      );
                    } else {
                      swal.fire(
                        this.translate("menu2.error"),
                        res.message,
                        "error"
                      );
                    }
                  } else if (res.message.name) {
                    swal.fire("error", res.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Delete":
              this.deleteSubDistric({ id: this.idSubDistric }).then((res) => {
                if (res.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire(
                    this.translate("menu.deleted"),
                    res.message,
                    "success"
                  );
                } else {
                  swal.fire(
                    this.translate("menu2.error"),
                    res.message,
                    "warning"
                  );
                }
              });
              break;
          }
          break;
      }
    },
    returnOldData(data, message, type) {
      swal
        .fire({
          title: "",
          // text: `DO YOU REALLY WANT TO DELETE ${data.name} FROM POSITION INFORMATION`,
          text: message,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.translate("menu.cancel"),
          confirmButtonText: this.translate("menu2.yes_return"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (type == "Province") {
              this.returnOldDataProvince(data).then((res) => {
                if (res.data.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire("", res.data.message, "success");
                }
              });
            } else if (type == "District") {
              this.returnOldDataDistric(data).then((res) => {
                if (res.data.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire("", res.data.message, "success");
                }
              });
            } else if (type == "SubDistrict") {
              this.returnOldDataSubDistric(data).then((res) => {
                if (res.data.success) {
                  this.$refs.manageLocationModal.closeModal();
                  swal.fire("", res.data.message, "success");
                }
              });
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.paddingcoffee {
  padding-right: 15px;
  padding-left: 15px;
}
.container-location {
  margin-top: 12px;

  .block-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;

    .block {
      width: 33%;

      &.province-block {
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(99, 0, 0);
      }
      &.distric-block {
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(99, 105, 0);
      }
      &.sub-distric-block {
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(0, 99, 8);
      }

      .title {
        font-size: 28px;
        background: #363636;
        padding: 18px 36px;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        color: #fff;

        &.center {
          justify-content: center;
        }

        .search-privince {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          i {
            font-size: 32px;
            margin-right: 8px;
          }

          input {
            font-size: 18px;
            width: 50%;
            height: 40px;
            border-radius: 8px;
            padding: 0px 8px;
          }
        }
      }
      .listData {
        .card-data {
          padding: 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #ffffff;
          border-radius: 10px;
          margin-bottom: 4px;
          // border-bottom: 1px solid #000;
          &.active {
            background: #eeeeee;
          }
          .name {
            font-size: 18px;
          }
          @media (max-width: 575.98px) {
            .name {
              font-size: 12px;
            }
          }
          .option {
            display: flex;
            font-size: 16px;

            .block-option {
              &:not(:first-child) {
                margin-left: 16px;
              }
              &.edit {
                color: #92b558;
                &:hover {
                  cursor: pointer;
                  color: #45572a;
                  transition-duration: 500ms;
                  font-size: 18px;
                }
              }
              &.delete {
                color: #d72727;
                &:hover {
                  cursor: pointer;
                  color: #811616;
                  transition-duration: 500ms;
                  font-size: 18px;
                }
              }
              &.select {
                font-weight: bold;
                display: flex;
                .block-icon {
                  margin-left: 8px;
                  background: #363636;
                  border-radius: 4px;
                  width: 22px;
                  height: 22px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  i {
                    color: #fff;
                  }
                }
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                  color: rgb(0, 104, 165);
                  transition-duration: 500ms;
                }
              }
            }
          }
        }
      }
      .block-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 12px 0px;
        .btn-add-data {
          display: flex;
          width: fit-content;
          padding: 4px 8px;
          box-shadow: 0px 4px 4px #00000040;
          background: #92b557;
          border-radius: 5px;
          color: #fff;
          font-size: 16px;

          i {
            margin-left: 12px;
            color: #fff;
          }

          &:hover {
            cursor: pointer;
            transition-duration: 500ms;
            background: #546832;
          }
        }
      }
    }
  }
}

// xs
@media screen and (max-width: 575.98px) {
  .paddingcoffee {
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-location {
    margin-top: 6px;
    .block-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;
      flex-wrap: wrap-reverse;

      .block {
        width: 33%;

        &.province-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 0, 0);
        }
        &.distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 105, 0);
        }
        &.sub-distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(0, 99, 8);
        }

        .title {
          font-size: 15px;
          background: #363636;
          padding: 18px 16px;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          color: #fff;

          &.center {
            justify-content: center;
          }

          .search-privince {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            i {
              font-size: 12px;
              margin-right: 4px;
              margin-left: 4px;
            }

            input {
              font-size: 12px;
              width: 50%;
              height: 25px;
              border-radius: 8px;
              padding: 0px 8px;
            }
          }
        }
        .subtitle {
          font-size: 13px;
        }
        .listData {
          .card-data {
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 4px;
            // border-bottom: 1px solid #000;
            &.active {
              background: #eeeeee;
            }
            .name {
              font-size: 12px;
            }

            .option {
              display: flex;
              font-size: 16px;

              .block-option {
                &:not(:first-child) {
                  margin-left: 16px;
                }
                &.edit {
                  color: #92b558;
                  &:hover {
                    cursor: pointer;
                    color: #45572a;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.delete {
                  color: #d72727;
                  &:hover {
                    cursor: pointer;
                    color: #811616;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.select {
                  font-weight: bold;
                  display: flex;
                  .block-icon {
                    margin-left: 8px;
                    background: #363636;
                    border-radius: 4px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                      color: #fff;
                    }
                  }
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: rgb(0, 104, 165);
                    transition-duration: 500ms;
                  }
                }
              }
            }
          }
        }
        .block-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 12px 0px;
          .btn-add-data {
            display: flex;
            width: fit-content;
            padding: 4px 8px;
            box-shadow: 0px 4px 4px #00000040;
            background: #92b557;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;

            i {
              margin-left: 12px;
              color: #fff;
            }

            &:hover {
              cursor: pointer;
              transition-duration: 500ms;
              background: #546832;
            }
          }
        }
      }
    }
  }
}
// sm
@media (max-width: 767.98px) {
  .paddingcoffee {
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-location {
    margin-top: 6px;
    .block-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;

      .block {
        width: 33%;

        &.province-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 0, 0);
        }
        &.distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 105, 0);
        }
        &.sub-distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(0, 99, 8);
        }

        .title {
          font-size: 15px;
          background: #363636;
          padding: 18px 16px;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          color: #fff;

          &.center {
            justify-content: center;
          }

          .search-privince {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            i {
              font-size: 12px;
              margin-right: 4px;
              margin-left: 4px;
            }

            input {
              font-size: 12px;
              width: 50%;
              height: 25px;
              border-radius: 8px;
              padding: 0px 8px;
            }
          }
        }
        .subtitle {
          font-size: 13px;
        }
        .listData {
          .card-data {
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 4px;
            // border-bottom: 1px solid #000;
            &.active {
              background: #eeeeee;
            }
            .name {
              font-size: 12px;
            }

            .option {
              display: flex;
              font-size: 16px;

              .block-option {
                &:not(:first-child) {
                  margin-left: 16px;
                }
                &.edit {
                  color: #92b558;
                  &:hover {
                    cursor: pointer;
                    color: #45572a;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.delete {
                  color: #d72727;
                  &:hover {
                    cursor: pointer;
                    color: #811616;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.select {
                  font-weight: bold;
                  display: flex;
                  .block-icon {
                    margin-left: 8px;
                    background: #363636;
                    border-radius: 4px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                      color: #fff;
                    }
                  }
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: rgb(0, 104, 165);
                    transition-duration: 500ms;
                  }
                }
              }
            }
          }
        }
        .block-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 12px 0px;
          .btn-add-data {
            display: flex;
            width: fit-content;
            padding: 4px 8px;
            box-shadow: 0px 4px 4px #00000040;
            background: #92b557;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;

            i {
              margin-left: 12px;
              color: #fff;
            }

            &:hover {
              cursor: pointer;
              transition-duration: 500ms;
              background: #546832;
            }
          }
        }
      }
    }
  }
}
// md
@media (max-width: 991.98px) {
  .paddingcoffee {
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-location {
    margin-top: 6px;
    .block-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;

      .block {
        width: 33%;

        &.province-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 0, 0);
        }
        &.distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 105, 0);
        }
        &.sub-distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(0, 99, 8);
        }

        .title {
          font-size: 15px;
          background: #363636;
          padding: 18px 16px;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          color: #fff;

          &.center {
            justify-content: center;
          }

          .search-privince {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            i {
              font-size: 12px;
              margin-right: 4px;
              margin-left: 4px;
            }

            input {
              font-size: 12px;
              width: 50%;
              height: 25px;
              border-radius: 8px;
              padding: 0px 8px;
            }
          }
        }
        .subtitle {
          font-size: 13px;
        }
        .listData {
          .card-data {
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 4px;
            // border-bottom: 1px solid #000;
            &.active {
              background: #eeeeee;
            }
            .name {
              font-size: 12px;
            }

            .option {
              display: flex;
              font-size: 16px;

              .block-option {
                &:not(:first-child) {
                  margin-left: 16px;
                }
                &.edit {
                  color: #92b558;
                  &:hover {
                    cursor: pointer;
                    color: #45572a;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.delete {
                  color: #d72727;
                  &:hover {
                    cursor: pointer;
                    color: #811616;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.select {
                  font-weight: bold;
                  display: flex;
                  .block-icon {
                    margin-left: 8px;
                    background: #363636;
                    border-radius: 4px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                      color: #fff;
                    }
                  }
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: rgb(0, 104, 165);
                    transition-duration: 500ms;
                  }
                }
              }
            }
          }
        }
        .block-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 12px 0px;
          .btn-add-data {
            display: flex;
            width: fit-content;
            padding: 4px 8px;
            box-shadow: 0px 4px 4px #00000040;
            background: #92b557;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;

            i {
              margin-left: 12px;
              color: #fff;
            }

            &:hover {
              cursor: pointer;
              transition-duration: 500ms;
              background: #546832;
            }
          }
        }
      }
    }
  }
}
</style>
