<template>
  <div class="container-excel">
    <div class="box-import-excel">
      <!-- <div class="row"> -->
      <label
        for="name"
        class="col-md-4 col-form-label text-md-right text-center"
        style="white-space: nowrap"
      >
        {{ translate("menu.choose_excel_file") }}
      </label>

      <div class="d-flex justify-content-center">
        <div class="cu_file_input">
          <button class="btn btn-secondary" @click="$refs.score_file.click()">
            {{ translate("menu.choose_file") }}
          </button>

          <input
            type="file"
            class="form-control inputimport"
            :class="{ ' is-invalid': errors.message }"
            id="input-file-import"
            name="file_import"
            ref="score_file"
            @change="onFileChange"
            hidden
          />

          <div class="name-file">
            {{
              this.score_file == ""
                ? translate("menu2.not_file")
                : this.score_file.name
            }}
          </div>
        </div>

        <div v-if="errors.message" class="invalid-feedback"></div>
      </div>

      <div class="box-btn">
        <button
          @click="uploadEx()"
          class="btn btn-primary uploadEx"
          :disabled="score_file ? false : true"
        >
          {{ translate("menu.upload") }}
        </button>
      </div>

      <!-- </div> -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      errors: { profile_name: "", message: "" },
      score_file: "",
      profile_name: "",
      datas: [],

      pagination: {},
      pagi: "",
      pgn_num: "",
      filter: {
        all: "",

        listNum: "",
        search: "",
      },
      isInputSearch: false,
    };
  },

  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.search) {
          this.isInputSearch = true;
        } else {
          this.isInputSearch = false;
        }
      },
    },
  },

  mounted() {},

  methods: {
    onFileChange(e) {
      this.score_file = e.target.files[0];
    },

    uploadEx() {
      $(".loader").show();
      let formData = new FormData();
      formData.append("score_file", this.score_file);

      axios
        // .post("/api/excel/import", formData)
        .post("/api/excel/import-score", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          $("#input-file-import").val("");
          this.score_file = "";
          $(".loader").hide();
          if (response.data.success) {
            swal.fire("Saved!", response.data.message, "success");
          } else {
            const self = this;
            if (response.data.data) {
              let datas = response.data.data;

              let detail = "";
              for (let i = 0; i < datas.length; i++) {
                detail += datas[i] + "<br>";
              }
              swal
                .fire({
                  title: response.data.message,
                  html: detail,
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: this.translate("menu.go_to_add_score"),
                })
                .then(function (result) {
                  if (result.isConfirmed) {
                    self.$router.push({
                      name: `score-categories`,
                    });
                  }
                });
            } else {
              let msg = response.data.message;
              let fword = "1048 Column '";
              let sword = "' cannot be null";
              let isConfbtn = true;
              console.log(msg.includes(sword));
              console.log(msg.includes(fword));
              if (msg.includes(sword) && msg.includes(fword)) {
                msg = msg.split(fword).pop().split(sword)[0];
                msg =
                  this.$language == "th"
                    ? "ช่องค่า " + msg + " เป็นว่างไม่ได้"
                    : "Column " + msg + " can't be empty";
                isConfbtn = false;
              }
              swal
                .fire({
                  title: "",
                  text: msg,
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: isConfbtn,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: this.translate("menu.cancel"),
                  confirmButtonText: this.translate("menu.go_to_add_score"),
                })
                .then(function (result) {
                  if (result.isConfirmed) {
                    self.$router.push({
                      name: `score-categories`,
                    });
                  }
                });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            swal.fire(
              this.translate("menu2.error"),
              error.response.data.message,
              "warning"
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.inputimport {
  margin-left: 18px;
  width: 240px;
}
.container-excel {
  margin-right: 32px;
  .box-import-excel {
    height: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 874px) {
      .box-btn {
        margin: 8px;
      }
    }
  }
}

.uploadEx {
  margin-left: 12px;
  margin-left: 10%;
  @media screen and (max-width: 575.98px) {
    margin-left: 100%;
  }
}

.btn {
  white-space: nowrap;
  z-index: 1;
}

.cu_file_input {
  position: relative;
  display: flex;
  .name-file {
    z-index: 0;
    max-width: 200px;
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0px 40px 0px 12px;
    background: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    color: #868e96;
    border: 1px solid #dee2e6;
    border-radius: 12px;
    height: 35px;
    cursor: pointer;
    &:hover {
      border-color: #ced4da;
      border-radius: 4px;
    }
  }
}

.excel_card {
  background-image: url("/img/xl.png");
  background-color: #cccccc;
  /* Used if the image is unavailable */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

input {
  content: none;
}
</style>
