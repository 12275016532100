
<template>
  <div class="container_coffee_spot_info">
    <!-- <div v-if="allVarietiesSelector.length > 1" class="con_variety_select"> -->
    <div class="con_variety_select">
      <div class="con_variety_select_center">
        <label class="title-input is-required">
          {{ translate("menu.please_select_a_variety") }}
          <span class="btn farm_name">
            <i class="fas fa-map-marker-alt"></i> {{ ` ${translate("menu.in")} ${name}` }}</span
          ></label
        >
      </div>
    </div>
    <div class="con_variety_select_year_variety">
      <div class="year_select">
        <vSelect
          class="form-control"
          label="name"
          :options="allYear"
          v-model="yearData"
          @input="searchYearToVariety($event)"
          :placeholder="translate('menu2.year')"
        >
        </vSelect>
      </div>
      <div class="variety_select">
        <vSelect
          class="form-control"
          :options="allSelectVarietyDate"
          label="name"
          v-model="variety"
          :clearable="false"
          :placeholder="translate('Please select')"
          @input="searchScore($event)"
        >
        </vSelect>
      </div>
    </div>
    <div class="con_pdf fix_width_preview center_not_zoom" v-if="showVariety">
      <div class="con_pdf_title">
        <h2 class="con_pdf_title_t">{{ allvarietiesInfo.coffeespot_name }}</h2>
        <div class="con_pdf_title_c">
          <div>
            {{
              `${allvarietiesInfo.subdistrict} ${allvarietiesInfo.district} ${allvarietiesInfo.province} ${allvarietiesInfo.postcode}`
            }}
          </div>
          <button class="btn_export_pdf" @click="generateReport()">
            <i class="fas fa-file-pdf"></i>
            Export PDF
          </button>
        </div>
      </div>
      <div class="con_pdf_img">
        <div class="con_pdf_img_a">
          <img :src="`/img/score/${allvarietiesInfo.image}`" alt="" />
        </div>
        <div class="con_pdf_img_b">
          <div class="con_pdf_img_b_size">
            <img
              class="con_pdf_img_b_size_center"
              :src="`/img/score/${allvarietiesInfo.before_pic}`"
              alt=""
            />
          </div>
          <div class="con_pdf_img_b_size">
            <img
              class="con_pdf_img_b_size_center"
              :src="`/img/score/${allvarietiesInfo.after_pic}`"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="con_pdf_basic_info">
        <div class="con_pdf_basic_info_data">
          <h2 class="con_pdf_basic_info_data_title">BASIC INFORMATION</h2>
          <div class="con_basic_info_data_demo card_info_pdf">
            <div class="basic_info_data">
              <span class="topic">VARIETY</span>
              <span class="score">{{ allvarietiesInfo.variety }}</span>
            </div>
            <div class="basic_info_data">
              <span class="topic">PROCESS</span>
              <span class="score">{{ allvarietiesInfo.process }}</span>
            </div>
            <div class="basic_info_data">
              <span class="topic">CUP SCORE</span>
              <span class="score">{{ allvarietiesInfo.cup_score }}</span>
            </div>
            <div class="basic_info_data">
              <span class="topic">SCORE DATE</span>
              <span class="score">{{ allvarietiesInfo.scored_date }}</span>
            </div>
            <div class="basic_info_data_textarea">
              <span class="topic">CHARACTERISTICS</span>
              <p class="score">{{ allvarietiesInfo.charact }}</p>
            </div>
          </div>
        </div>
        <div v-if="showChart" class="spider_graph">
          <div class="spider_graph_title">CUPPING SCORE</div>
          <div class="con_graph">
            <apexcharts
              width="225"
              height="200"
              type="radar"
              :options="chartOptions"
              :series="series"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div
        class="con_pdf_score_demo"
        v-if="
          allvarietiesInfo.categories && allvarietiesInfo.categories.length > 0
        "
      >
        <div
          class="con_pdf_score_demo_topic_demo"
          v-for="(cate, index) in allvarietiesInfo.categories"
          :key="index"
        >
          <h2 class="con_pdf_score_demo_title_demo">{{ cate.name }}</h2>
          <div class="con_card">
            <div
              v-for="(data, index) in cate.scores"
              :key="index"
              class="con_score_demo"
            >
              <span>{{
                ` ${data.score_name} ${data.unit ? data.unit : ""} `
              }}</span>
              <span>{{ data.score }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="true"
      ref="html2Pdf"
      :html-to-pdf-options="htmlToPdfOptions"
    >
      <section slot="pdf-content">
        <section class="pdf-item" id="page_size">
          <div class="con_pdf fix_width_preview">
            <div class="con_pdf_title">
              <h2 class="con_pdf_title_t">
                {{ allvarietiesInfo.coffeespot_name }}
              </h2>
              <div class="con_pdf_title_c">
                <span>{{
                  `${allvarietiesInfo.subdistrict} ${allvarietiesInfo.district} ${allvarietiesInfo.province} ${allvarietiesInfo.postcode}`
                }}</span>
              </div>
            </div>
            <div class="con_pdf_img">
              <div class="con_pdf_img_a">
                <img :src="`/img/score/${allvarietiesInfo.image}`" alt="" />
              </div>
              <div class="con_pdf_img_b">
                <div class="con_pdf_img_b_size">
                  <img
                    class="con_pdf_img_b_size_center"
                    :src="`/img/score/${allvarietiesInfo.before_pic}`"
                    alt=""
                  />
                </div>
                <div class="con_pdf_img_b_size">
                  <img
                    class="con_pdf_img_b_size_center"
                    :src="`/img/score/${allvarietiesInfo.after_pic}`"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="con_pdf_basic_info">
              <div class="con_pdf_basic_info_data">
                <h2 class="con_pdf_basic_info_data_title">BASIC INFORMATION</h2>
                <div id="basic_info" class="con_basic_info_data card_info_pdf">
                  <div class="basic_info_data">
                    <span class="topic">VARIETY</span>
                    <span class="score">{{ allvarietiesInfo.variety }}</span>
                  </div>
                  <div class="basic_info_data">
                    <span class="topic">PROCESS</span>
                    <span class="score">{{ allvarietiesInfo.process }}</span>
                  </div>
                  <div class="basic_info_data">
                    <span class="topic">CUP SCORE</span>
                    <span class="score">{{ allvarietiesInfo.cup_score }}</span>
                  </div>
                  <div class="basic_info_data">
                    <span class="topic">SCORE DATE</span>
                    <span class="score">{{
                      allvarietiesInfo.scored_date
                    }}</span>
                  </div>
                  <div class="basic_info_data_textarea">
                    <span class="topic">CHARACTERISTICS</span>
                    <p class="score">
                      {{ allvarietiesInfo.charact }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="showChart" class="spider_graph">
                <div class="spider_graph_title">SPIDER GRAPH</div>
                <div class="con_graph">
                  <apexcharts
                    width="225"
                    height="200"
                    type="radar"
                    :options="chartOptions"
                    :series="series"
                  ></apexcharts>
                </div>
              </div>
            </div>
            <div
              class="con_pdf_score"
              v-if="
                allvarietiesInfo.categories &&
                allvarietiesInfo.categories.length > 0
              "
            >
              <div
                class="con_pdf_score_topic"
                v-for="(cate, index) in allvarietiesInfo.categories"
                :key="index"
              >
                <h2 class="con_pdf_score_title">{{ cate.name }}</h2>
                <div class="con_card">
                  <div
                    v-for="(data, index) in cate.scores"
                    :key="index"
                    class="con_score"
                  >
                    <span>{{
                      ` ${data.score_name} ${data.unit ? data.unit : ""} `
                    }}</span>
                    <span>{{ data.score }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import vSelect from "vue-select";
import moment from "moment";
export default {
  props: ["location", "name", "year", "varietyid"],
  components: { VueHtml2pdf, apexcharts: VueApexCharts, vSelect },
  mounted() {
    let self = this;
    if (this.year == "undefined") {
      this.getVarietyYear(this.location);
    } else {
      this.getVarietyYear(this.location, true);
      this.yearData = this.year;
      this.formYearVariety.year = this.year;
      this.formYearVariety.id = this.location;
      this.getAllVarietyDate(this.formYearVariety).then((response) => {
        if (response.data.success) {
          if (this.varietyid != "undefined") {
            this.allSelectVarietyDate.forEach((element) => {
              if (element.id == this.varietyid) {
                self.variety = element.name;
              }
            });
            this.htmlToPdfOptions.filename = `${this.variety}.pdf`;
            this.setPdf();
          } else {
            let vari_id = response.data.message[0].id;
            this.variety = response.data.message[0].name;
            this.htmlToPdfOptions.filename = `${this.variety}.pdf`;
            this.setPdf(vari_id);
          }
        }
      });
    }
    if (document.body.offsetWidth < 992) {
      self.htmlToPdfOptions.margin = [0.1, 0, 0.3, 0];
    } else {
      self.htmlToPdfOptions.margin = [0.1, -1.37, 0.3, 0];
    }
    window.addEventListener("resize", CheckBrowserSize, false);
    function CheckBrowserSize() {
      if (document.body.offsetWidth < 992) {
        self.htmlToPdfOptions.margin = [0.1, 0, 0.3, 0];
      } else {
        self.htmlToPdfOptions.margin = [0.1, -1.37, 0.3, 0];
      }
    }
  },
  data() {
    return {
      yearData: "",
      allYear: [],
      allVariety: [],
      showVariety: false,
      variety: "",
      formYearVariety: {
        id: "",
        year: "",
      },

      htmlToPdfOptions: {
        margin: [0.1, -1.37, 0.3, 0],
        // margin: [0.1, 0, 0.3, 0],
        filename: "",
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      },

      locationImg: [
        {
          name: "/img/info/1.png",
        },
        {
          name: "/img/info/2.png",
        },
        {
          name: "/img/info/3.png",
        },
      ],
      showChart: false,
      dataLabels: {
        style: {
          fontSize: "50px",
          fontWeight: "bold",
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: "#fff",
        },
      },
      chartOptions: {
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },

        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    };
  },
  computed: mapGetters([
    "allVarietiesSelector",
    "allvarietiesInfo",
    "allYearVariety",
    "allSelectVarietyDate",
  ]),
  watch: {
    allVarietiesSelector() {
      if (this.allVarietiesSelector.length == 1) {
        this.getVarietyInfo(this.allVarietiesSelector[0].id);
        this.showVariety = true;
      }
    },
    allvarietiesInfo() {},
  },
  methods: {
    ...mapActions([
      "getVarietyInfo",
      "getVarietiesSelector",
      "getVarietyAllYear",
      "getAllVarietyYear",
      "getAllVarietyDate",
    ]),
    searchYearToVariety(e) {
      this.formYearVariety.year = e;
      this.formYearVariety.id = this.location;
      this.getAllVarietyDate(this.formYearVariety);
    },
    setPdf(var_id) {
      let self = this;
      this.getVarietyInfo(var_id ? var_id : this.varietyid)
        .then((response) => {
          if (response.data.success) {
            let dataVariety = response.data.message.categories;
            let dataVarietySet = response.data.message.categories;

            dataVariety.forEach((data, index) => {
              let scoreData = data.scores;
              if (scoreData.length > 0) {
                scoreData.forEach((ele, i) => {
                  if (ele.score == null) {
                    dataVarietySet[index].scores[i].score = "-";
                  }
                });
              }
            });
            let removeCategories = [];
            dataVariety.forEach((elem, indexe) => {
              if (elem.scores.length == 0) {
                dataVarietySet[indexe] = "delete";
              }
            });
            removeCategories = dataVarietySet.filter(function (e) {
              return e !== "delete";
            });
            this.allvarietiesInfo.categories = removeCategories;

            if (response.data.message.categories.length > 0) {
              let nameScore = response.data.message.categories[0].scores;
              self.series[0].name = response.data.message.categories[0].name;
              nameScore.forEach((scores) => {
                self.chartOptions.xaxis.categories.push(scores.score_name);
                if (scores.score == "-") {
                  self.series[0].data.push(0);
                } else if (scores.score != null) {
                  self.series[0].data.push(scores.score);
                } else {
                  self.series[0].data.push(0);
                }
              });
              this.showChart = true;
            } else {
              this.showChart = false;
              document.documentElement.style.setProperty(
                "--grid-template-column",
                "100%"
              );
            }

            this.showVariety = true;
            this.formatPDF();
          }
        })
        .catch((error) => {
          reject(error);
        });
    },

    searchScore(e) {
      window.location.href = `/variety-info/${this.location}/${this.name}/${this.formYearVariety.year}/${e.id}`;
    },
    getVarietyYear(id, val_first) {
      this.getAllVarietyYear(id)
        .then((res) => {
          let allYearPass = [];
          if (res.data.success) {
            let date = res.data.message;
            date.forEach((element) => {
              let toWord = moment(element.name).format("YYYY");
              toWord.toString();
              allYearPass.push(toWord);
            });
            if (!val_first) {
              this.yearData = allYearPass[0];
              this.formYearVariety.year = allYearPass[0];
              this.formYearVariety.id = this.location;
              this.getAllVarietyDate(this.formYearVariety).then((response) => {
                if (response.data.success) {
                  let vari_id = response.data.message[0].id;
                  this.variety = response.data.message[0].name;
                  this.htmlToPdfOptions.filename = `${this.variety}.pdf`;
                  this.setPdf(vari_id);
                }
              });
            }
          }

          this.allYear = allYearPass.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          });
        })
        .catch((error) => {});
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    formatPDF() {
      let heightPage = 1060;
      let contentSize = 450.49;
      let paddingButtom = 15;
      let pageSize = document.getElementById("page_size");
      let basicInfo = document.getElementById("basic_info");
      let roundPage = 0;
      //   let h_event = 0; //เลขคู่
      //   let h_odd = 0; //เลขคี่
      //   console.log("pageAllSize:: ", pageSize.offsetHeight);
      //   console.log("page:: 1060");
      let h_data = contentSize + basicInfo.offsetHeight;
      let h_remain = heightPage - h_data;
      let round = 0;
      let total_categories = 0;
      let score = document.querySelectorAll(".con_pdf_score");
      let scoreChilden = score[0].children;
      let break_All = 0;

      for (round; total_categories < h_remain; round++) {
        // categories เอามาบวก เฉพาะ เลขคี่
        let n = round % 2 == 0 ? 0 : 1;
        if (n == 1) {
          total_categories =
            scoreChilden[round].offsetHeight + total_categories + 16;
        }
      }
      let round_categories = round - 3;
      let check_total_categories_before = 0;

      for (let r = 0; r < round - 1; r++) {
        let n_s = r % 2 == 0 ? 0 : 1;
        if (n_s == 1) {
          check_total_categories_before =
            scoreChilden[r].offsetHeight + check_total_categories_before + 16;
        }
      }

      if (check_total_categories_before < h_remain) {
        let sum = h_remain - check_total_categories_before;
        if (sum > 120) {
          let r_scorename = round - 2;
          for (let i = 0; i <= 1; i++) {
            let padding = 15; // padding ของ class con_card
            let gap = 4; // gap ของ class con_card
            let scoreName = scoreChilden[r_scorename].children[1].children;
            let total_scoreName = padding;
            let sum_check_scorename = sum - 80; // loop ข้างใน
            let u = 0;

            for (
              u;
              total_scoreName < sum_check_scorename && scoreName[u];
              u++
            ) {
              total_scoreName =
                scoreName[u].offsetHeight + total_scoreName + gap;
            }

            if (total_scoreName > sum_check_scorename) {
              let n_children = r_scorename % 2 == 0 ? 0 : 1;
              let r_scorename_cal = 0;
              if (n_children == 0) {
                r_scorename_cal = r_scorename + 1;
              } else if (n_children == 1) {
                r_scorename_cal = r_scorename + 1;
              }
              let score_fix =
                document.querySelectorAll(".con_pdf_score")[0].children;
              let catDiv = document.createElement("div");
              catDiv.classList.add(`con_pdf_score_topic`);
              let loopDiv = document.createElement("div");
              loopDiv.classList.add("con_card");
              score_fix[r_scorename_cal].parentNode.insertBefore(
                catDiv,
                score_fix[r_scorename_cal].nextSibling
              );
              let r_scorename_cal_cal = 0;
              if (n_children == 0) {
                r_scorename_cal_cal = r_scorename + 2;
              } else if (n_children == 1) {
                r_scorename_cal_cal = r_scorename + 2;
              }

              let score_fix_fix =
                document.querySelectorAll(".con_pdf_score")[0].children;
              score_fix_fix[r_scorename_cal_cal].appendChild(loopDiv);

              let b = u - 1;

              for (b; b < scoreName.length; b++) {
                let sc_insert = score_fix[r_scorename_cal_cal].children[0];
                sc_insert.appendChild(scoreName[b]);
                b = b - 1;
              }
              let div_break_loop_children = document.createElement("div");
              div_break_loop_children.classList.add("html2pdf__page-break");
              let r_scorename_loop_children = 0;

              //   break_All = break_All + 1;
              if (break_All > 0) {
                if (n_children == 0) {
                  r_scorename_loop_children = r_scorename + 1;
                  let score_fix_loop_children =
                    document.querySelectorAll(".con_pdf_score")[0].children;

                  score_fix_loop_children[
                    r_scorename_loop_children
                  ].parentNode.insertBefore(
                    div_break_loop_children,
                    score_fix_loop_children[r_scorename_loop_children]
                      .nextSibling
                  );
                } else if (n_children == 1) {
                  r_scorename_loop_children = r_scorename;
                  let score_fix_loop_children =
                    document.querySelectorAll(".con_pdf_score")[0].children;

                  score_fix_loop_children[
                    r_scorename_loop_children
                  ].parentNode.insertBefore(
                    div_break_loop_children,
                    score_fix_loop_children[r_scorename_loop_children]
                      .nextSibling
                  );
                }
              }
            } else {
              let n_children_else = r_scorename % 2 == 0 ? 0 : 1;
              let r_scorename_cal_else = 0;
              if (n_children_else == 0) {
                r_scorename_cal_else = r_scorename + 1;
              } else if (n_children_else == 1) {
                r_scorename_cal_else = r_scorename + 1;
              }
              let score_fix =
                document.querySelectorAll(".con_pdf_score")[0].children;
              let catDiv = document.createElement("div");
              catDiv.classList.add("con_pdf_score_topic");
              let loopDiv = document.createElement("div");
              loopDiv.classList.add("con_card");
              score_fix[r_scorename_cal_else].parentNode.insertBefore(
                catDiv,
                score_fix[r_scorename_cal_else].nextElementSibling
              );
            }
            r_scorename = r_scorename + 1;
          }

          let break_div_end_loop = document.createElement("div");
          break_div_end_loop.classList.add("html2pdf__page-break");
          let score_end_loop =
            document.querySelectorAll(".con_pdf_score")[0].children;
          score_end_loop[round - 1].parentNode.insertBefore(
            break_div_end_loop,
            score_end_loop[round - 1].nextElementSibling
          );
        } else {
          let div_break_1 = document.createElement("div");
          div_break_1.classList.add("html2pdf__page-break");
          scoreChilden[round_categories].parentNode.insertBefore(
            div_break_1,
            scoreChilden[round_categories].nextElementSibling
          );
        }
      } else {
      }
    },
  },
};
</script>

<style lang="scss" >
:root {
  --grid-template-column: 69% 30%;
}
// different screen resolutions will come out not the same *************
.fix_width_preview {
  //   max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  width: 800px;
  //   height: 419.94px;
}
.center_not_zoom {
  @media (max-width: 830px) {
    transform: scale(0.7) translate(-3%, -20%);
  }
  @media (max-width: 795px) {
    transform: scale(0.7) translate(-9%, -20%);
  }
  @media (max-width: 728px) {
    transform: scale(0.7) translate(-16%, -20%);
  }
  @media (max-width: 659px) {
    transform: scale(0.7) translate(-22%, -20%);
  }
  @media (max-width: 616px) {
    transform: scale(0.7) translate(-22%, -21%);
  }
  @media (max-width: 587px) {
    transform: scale(0.5) translate(-41%, -49%);
  }
  @media (max-width: 507px) {
    transform: scale(0.5) translate(-50%, -49%);
  }
  @media (max-width: 466px) {
    transform: scale(0.4) translate(-75%, -75%);
  }
  @media (max-width: 388px) {
    transform: scale(0.3) translate(-113%, -117%);
  }
}
.con_pdf {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  &_title {
    &_t {
      margin-bottom: 0rem;
    }
    &_c {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
    }
  }
  &_img {
    display: grid;
    grid-template-columns: 78% 22%;
    gap: 5px;

    &_a {
      border-radius: 10px;
      overflow: hidden;
      height: 295px;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 610px;
      }
    }
    &_b {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-radius: 10px;
      overflow: hidden;
      &_size {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 145px;
        position: relative;
        &_center {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 240px;
        }
      }
    }
    img {
      border-radius: 10px;
    }
  }
  &_basic_info {
    display: grid;
    grid-template-columns: var(--grid-template-column);
    column-gap: 8px;
    &_data {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &_title {
        text-align: center;
      }
      .con_basic_info_data {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .basic_info_data {
          display: grid;
          grid-template-columns: 22% 78%;
        }
      }
      .con_basic_info_data_demo {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .basic_info_data {
          display: grid;
          grid-template-columns: 22% 78%;
        }
      }
    }
    .spider_graph {
      padding-top: 3.08rem;
      &_title {
        font-size: 1.4rem;
        color: #2c2c2c;
        background: #add669;
        border-radius: 10px;
        text-align: center;
      }
    }
  }
  &_score {
    // display: grid;
    // grid-template-columns: 44% 44%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 86px;

    // row-gap: 16px;
    &_topic {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 334px;
      margin-top: 1px;
      .con_card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        background: #ffffff;
        border: 2px solid #c5c5c5;
        border-radius: 10px;
        padding: 15px;
        word-break: break-all;
        height: 100%;
        width: 100%;
        // padding-bottom: 12px;
        .con_score {
          //   border: 2px solid #c5c5c5;
          //   border-radius: 10px;
          word-break: break-all;
          display: flex;
          flex-direction: row;
          gap: 11px;
          //   padding: 10px 4px;
          //   justify-content: center;
        }
        .con_score span:first-child {
          font-weight: 600;
        }
      }
    }
    &_title {
      font-size: 1.2rem;
      line-height: 27.05px;
      //   line-height: 47.05px;
    }
  }
  &_score_demo {
    display: grid;
    grid-template-columns: 44% 44%;
    column-gap: 86px;
    row-gap: 16px;
    &_topic_demo {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .con_card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        background: #ffffff;
        border: 2px solid #c5c5c5;
        border-radius: 10px;
        padding: 15px;
        word-break: break-all;
        height: 100%;
        // padding-bottom: 12px;
        .con_score_demo {
          //   border: 2px solid #c5c5c5;
          //   border-radius: 10px;
          word-break: break-all;
          display: flex;
          flex-direction: row;
          gap: 11px;
          //   padding: 10px 4px;
          //   justify-content: center;
        }
        .con_score_demo span:first-child {
          font-weight: 600;
        }
      }
    }
    &_title_demo {
      font-size: 1.2rem;
      line-height: 27.05px;
      //   line-height: 47.05px;
    }
  }
}

.container_coffee_spot_info {
  padding: 10px;
  .btn_export_pdf {
    font-size: 19px;
    color: #ffffff;
    background: #e96868;
    border-radius: 10px;
    border: none;
    padding: 4px 14px;
    transition: all 0.4s;
    &:hover {
      background: #c71212;
    }
  }
}
h2 {
  margin-bottom: 0rem;
}
.card_info_pdf {
  background: #ffffff;
  border: 3px solid #c5c5c5;
  border-radius: 10px;
  padding: 15px 10px 0px 10px;
  word-break: break-all;
}
.topic {
  font-weight: 600;
}
.score {
  font-weight: 400;
  word-break: break-word;
}
.con_graph {
  height: 160px;
}
.con_variety_select {
  width: 100%;
  display: flex;
  justify-content: center;
  //   margin-bottom: 2rem;
  &_center {
    display: flex;
    justify-content: center;
  }
}
.farm_name {
  background: #92b558;
  color: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 10px;
  padding: 4px 12px;
}
.con_variety_select_year_variety {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  .year_select {
    width: 9rem;
  }
  .variety_select {
    width: 28rem;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}

.html2pdf__page-break {
  width: 334px;
  height: 0px;
}
</style>
