
<template>
  <div class="paddingcoffee">
    <h1 class="text-uppercase">{{ translate("menu.Score_Categories") }}</h1>

    <div class="container-location">
      <div class="block-content">
        <div class="block province-block" id="province-block">
          <div class="title">{{ translate("menu.categories") }}</div>
          <div class="block-btn">
            <div
              class="btn-add-data"
              @click="openModalScore('Add', 'ScoreCategories', null)"
            >
              {{ translate("menu.add_categories") }}
              <div class="block-icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="listData">
            <draggable
              v-bind="dragOptions"
              @start="drag = true"
              :list="scoreCategories"
              @end="checkMove('categories')"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <div v-for="data in scoreCategories" :key="data.id">
                  <div class="form-group card-data">
                    <div class="name">{{ data.name }}</div>
                    <div class="option">
                      <div
                        class="block-option edit"
                        @click="
                          (idScore = data.id),
                            openModalScore('Edit', 'ScoreCategories', data.name)
                        "
                      >
                        <i class="fas fa-edit"></i>
                      </div>
                      <div
                        class="block-option delete"
                        @click="
                          (idScore = data.id),
                            openModalScore(
                              'Delete',
                              'ScoreCategories',
                              data.name
                            )
                        "
                      >
                        <i class="fas fa-trash-alt"></i>
                      </div>
                      <div
                        class="block-option select subtitle"
                        @click="
                          getScoreName(data.id),
                            (idScore = data.id),
                            (isShowScore = true),
                            (isNameScore = data.name)
                        "
                      >
                        <span
                          class="
                            d-none
                            d-md-block
                            d-lg-none
                            d-lg-block
                            d-xl-none
                            d-xl-block
                            subtitle
                          "
                          >{{ translate("menu.add_score_name") }}</span
                        >

                        <div class="block-icon">
                          <i class="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
        <div
          v-show="isShowScore"
          class="block distric-block"
          id="distric-block"
        >
          <div class="title">
            {{ translate("menu.score_name") }} {{ isNameScore }}
          </div>
          <div class="block-btn">
            <div
              class="btn-add-data"
              @click="openModalScore('Add', 'ScoreName', null)"
            >
              {{ translate("menu.score_name") }}
              <div class="block-icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="listData">
            <draggable
              v-bind="dragOptions"
              @start="drag = true"
              :list="scoreNameByid"
              @end="checkMove('scoreName')"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <div v-for="data in scoreNameByid" :key="data.id">
                  <div class="form-group card-data">
                    <div class="name">
                      {{ `${data.name} ${data.unit ? data.unit : ""}` }}
                    </div>
                    <div class="option">
                      <div
                        class="block-option edit"
                        @click="
                          (idScorename = data.id),
                            openModalScore(
                              'Edit',
                              'ScoreName',
                              data.name,
                              data.unit
                            )
                        "
                      >
                        <i class="fas fa-edit"></i>
                      </div>
                      <div
                        class="block-option delete"
                        @click="
                          (idScorename = data.id),
                            openModalScore('Delete', 'ScoreName', data.name)
                        "
                      >
                        <i class="fas fa-trash-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
        <div v-show="!isShowScore" class="block"></div>
      </div>
    </div>
    <ScoreModal ref="scoremodal" @actionLocationModal="caseModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ScoreModal from "./ScoreModal";
import draggable from "vuedraggable";
import debounce from "lodash/debounce";

export default {
  props: [],
  components: { ScoreModal, draggable },
  data() {
    return {
      idScore: null,
      idScorename: null,
      idSubDistric: null,
      isShowScore: false,

      drag: false,
      score_categories: [],

      isNameScore: "",
    };
  },
  computed: {
    ...mapGetters(["scoreNameByid", "scoreCategories"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        on: {
          change: this.handleChange,
          input: this.inputChanged,
        },
      };
    },
  },
  watch: {
    idScore() {},
    idScorename() {},
  },
  mounted() {
    this.getScoreCategories();

    Fire.$on("update", () => {
      this.getScoreCategories();
    });

    Fire.$on("updateNameByid", () => {
      this.getScoreName(this.idScore);
    });
  },
  methods: {
    ...mapActions([
      "getScoreCategories",
      "createScoreCategories",
      "updateScoreCategories",
      "deleteScoreCategories",
      "createScoreName",
      "getScoreName",
      "updateScoreName",
      "deleteScoreName",
      "updateSequencer",
      "updateScoreNameSeq",
      "returnOldDataScoreName",
      "returnOldDataScoreCategories",
    ]),
    openModalScore(action, group, name, unit) {
      let newDate = {
        action: action,
        group: group,
        name: name,
        unit: unit ? unit : null,
      };

      this.$refs.scoremodal.openModal(newDate);
    },

    caseModal(data) {
      switch (data.group) {
        case "ScoreCategories":
          switch (data.action) {
            case "Add":
              this.createScoreCategories({ name: data.name }).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  Fire.$emit("update");
                  swal.fire("Success", res.message, "success");
                } else {
                  if (res.data.message.name) {
                    swal.fire("error", res.data.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.data.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Edit":
              this.updateScoreCategories({
                id: this.idScore,
                name: data.name,
              }).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  Fire.$emit("update");
                  swal.fire("Success", res.message, "success");
                } else {
                  if (this.$errorMsg(res.data.message)) {
                    if (res.data.existed_data) {
                      this.returnOldData(
                        res.data.existed_data,
                        res.data.message,
                        "ScoreCat"
                      );
                    } else {
                      swal.fire(
                        this.translate("menu2.error"),
                        res.data.message,
                        "error"
                      );
                    }
                  } else if (res.data.message.name) {
                    swal.fire("error", res.data.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.data.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Delete":
              this.deleteScoreCategories(this.idScore).then((res) => {
                Fire.$emit("update");
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  this.isShowScore = false;
                  // swal.fire("Success", res.message, "success");
                } else {
                  swal.fire("error!", res.message, "warning");
                }
              });
              this.checkMove("categories");
              break;
          }
          break;
        case "ScoreName":
          switch (data.action) {
            case "Add":
              this.createScoreName({
                name: data.name,
                scca_id: this.idScore,
                unit: data.unit,
              }).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  Fire.$emit("updateNameByid");
                  swal.fire("Success", res.message, "success");
                } else {
                  // this.$validateMsg(res.data.message);
                  swal.fire("error", res.data.message.name[0], "warning");
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Edit":
              this.updateScoreName({
                id: this.idScorename,
                name: data.name,
                scca_id: this.idScore,
                unit: data.unit,
              }).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  Fire.$emit("updateNameByid");
                  swal.fire("Success", res.message, "success");
                } else {
                  if (this.$errorMsg(res.data.message)) {
                    if (res.data.existed_data) {
                      this.returnOldData(
                        res.data.existed_data,
                        res.data.message,
                        "ScoreName"
                      );
                    } else {
                      swal.fire(
                        this.translate("menu2.error"),
                        res.data.message,
                        "error"
                      );
                    }
                  } else if (res.data.message.name) {
                    swal.fire("error", res.data.message.name[0], "warning");
                  } else {
                    swal.fire("error", res.data.message, "warning");
                  }
                  Fire.$emit("validate", res);
                }
              });
              break;
            case "Delete":
              this.deleteScoreName(this.idScorename).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  Fire.$emit("updateNameByid");
                  // swal.fire("Success", res.message, "success");
                } else {
                  swal.fire("error!", res.message, "warning");
                }
              });
              this.checkMove("scoreName");
              break;
          }
          break;
      }
    },
    returnOldData(data, message, type) {
      swal
        .fire({
          title: "",
          // text: `DO YOU REALLY WANT TO DELETE ${data.name} FROM POSITION INFORMATION`,
          text: message,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.translate("menu.cancel"),
          confirmButtonText: this.translate("menu2.yes_return"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (type == "ScoreCat") {
              this.returnOldDataScoreCategories(data).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  swal.fire("", res.data.message, "success");
                }
              });
            } else if (type == "ScoreName") {
              this.returnOldDataScoreName(data).then((res) => {
                if (res.data.success) {
                  this.$refs.scoremodal.closeModal();
                  swal.fire("", res.data.message, "success");
                }
              });
            }
          }
        });
    },
    checkMove(name) {
      if (name == "categories") {
        setTimeout(() => {
          let arrayAllData = [];
          this.scoreCategories.filter((item, index) => {
            index++;
            item.seq = index;
            arrayAllData.push({ id: item.id, seq: item.seq });
          });
          this.updateSequencer(arrayAllData).then((res) => {
            if (res.data.success) {
              swal.fire("Success", res.message, "success");
            } else {
              swal.fire("error!", res.message, "warning");
            }
          });
        }, 500);
      } else {
        setTimeout(() => {
          let arrayAllData = [];
          this.scoreNameByid.filter((item, index) => {
            index++;
            item.seq = index;
            arrayAllData.push({ id: item.id, seq: item.seq });
          });
          this.updateScoreNameSeq(arrayAllData).then((res) => {
            if (res.data.success) {
              swal.fire("Success", res.message, "success");
            } else {
              swal.fire("error!", res.message, "warning");
            }
          });
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-location {
  margin-top: 12px;

  .block-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;

    .block {
      width: 48%;

      &.province-block {
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(99, 0, 0);
      }
      &.distric-block {
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(99, 105, 0);
      }
      &.sub-distric-block {
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(0, 99, 8);
      }

      .title {
        font-size: 28px;
        background: #363636;
        padding: 18px 36px;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        color: #fff;

        &.center {
          justify-content: center;
        }

        .search-privince {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          i {
            font-size: 32px;
            margin-right: 8px;
          }

          input {
            font-size: 18px;
            width: 50%;
            height: 40px;

            @media screen and (max-width: 1280px) {
              width: 80%;
            }
          }
        }
      }
      .listData {
        .card-data {
          padding: 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #ffffff;
          border-radius: 10px;
          margin-bottom: 4px;
          // border-bottom: 1px solid #000;
          &.active {
            background: #eeeeee;
          }
          .name {
            font-size: 18px;
          }
          .option {
            display: flex;
            font-size: 16px;

            .block-option {
              &:not(:first-child) {
                margin-left: 16px;
              }
              &.edit {
                color: #92b558;
                &:hover {
                  cursor: pointer;
                  color: #45572a;
                  transition-duration: 500ms;
                  font-size: 18px;
                }
              }
              &.delete {
                color: #d72727;
                &:hover {
                  cursor: pointer;
                  color: #811616;
                  transition-duration: 500ms;
                  font-size: 18px;
                }
              }
              &.select {
                font-weight: bold;
                display: flex;
                .block-icon {
                  margin-left: 8px;
                  background: #363636;
                  border-radius: 4px;
                  width: 22px;
                  height: 22px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  i {
                    color: #fff;
                  }
                }
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                  color: rgb(0, 104, 165);
                  transition-duration: 500ms;
                }
              }
            }
          }
        }
      }
      .block-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 12px 0px;
        .btn-add-data {
          display: flex;
          width: fit-content;
          padding: 4px 8px;
          box-shadow: 0px 4px 4px #00000040;
          background: #92b557;
          border-radius: 5px;
          color: #fff;
          font-size: 16px;

          i {
            margin-left: 12px;
            color: #fff;
          }

          &:hover {
            cursor: pointer;
            transition-duration: 500ms;
            background: #546832;
          }
        }
      }
    }
  }
}
// xs
@media screen and (max-width: 575.98px) {
  .paddingcoffee {
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-location {
    margin-top: 6px;
    .block-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;
      flex-wrap: wrap-reverse;

      .block {
        width: 33%;

        &.province-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 0, 0);
        }
        &.distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 105, 0);
        }
        &.sub-distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(0, 99, 8);
        }

        .title {
          font-size: 15px;
          background: #363636;
          padding: 18px 16px;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          color: #fff;
          text-align: center;

          &.center {
            justify-content: center;
          }
        }
        .subtitle {
          font-size: 13px;
        }
        .listData {
          .card-data {
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 4px;
            // border-bottom: 1px solid #000;
            &.active {
              background: #eeeeee;
            }
            .name {
              font-size: 12px;
            }

            .option {
              display: flex;
              font-size: 16px;

              .block-option {
                &:not(:first-child) {
                  margin-left: 16px;
                }
                &.edit {
                  color: #92b558;
                  &:hover {
                    cursor: pointer;
                    color: #45572a;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.delete {
                  color: #d72727;
                  &:hover {
                    cursor: pointer;
                    color: #811616;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.select {
                  font-weight: bold;
                  display: flex;
                  .block-icon {
                    margin-left: 8px;
                    background: #363636;
                    border-radius: 4px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                      color: #fff;
                    }
                  }
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: rgb(0, 104, 165);
                    transition-duration: 500ms;
                  }
                }
              }
            }
          }
        }
        .block-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 12px 0px;
          .btn-add-data {
            display: flex;
            width: fit-content;
            padding: 4px 8px;
            box-shadow: 0px 4px 4px #00000040;
            background: #92b557;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;

            i {
              margin-left: 12px;
              color: #fff;
            }

            &:hover {
              cursor: pointer;
              transition-duration: 500ms;
              background: #546832;
            }
          }
        }
      }
    }
  }
}
// sm
@media (max-width: 991.98px) {
  .paddingcoffee {
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-location {
    margin-top: 6px;
    .block-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;
      .block {
        width: 33%;

        &.province-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 0, 0);
        }
        &.distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(99, 105, 0);
        }
        &.sub-distric-block {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          margin-right: 2px;
          margin-left: 2px;
          // background: rgb(0, 99, 8);
        }

        .title {
          font-size: 15px;
          background: #363636;
          padding: 18px 16px;
          border-radius: 20px 20px 0px 0px;
          display: flex;
          color: #fff;
          text-align: center;

          &.center {
            justify-content: center;
          }
        }
        .subtitle {
          font-size: 13px;
        }
        .listData {
          .card-data {
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 4px;
            // border-bottom: 1px solid #000;
            &.active {
              background: #eeeeee;
            }
            .name {
              font-size: 12px;
            }

            .option {
              display: flex;
              font-size: 16px;

              .block-option {
                &:not(:first-child) {
                  margin-left: 16px;
                }
                &.edit {
                  color: #92b558;
                  &:hover {
                    cursor: pointer;
                    color: #45572a;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.delete {
                  color: #d72727;
                  &:hover {
                    cursor: pointer;
                    color: #811616;
                    transition-duration: 500ms;
                    font-size: 18px;
                  }
                }
                &.select {
                  font-weight: bold;
                  display: flex;
                  .block-icon {
                    margin-left: 8px;
                    background: #363636;
                    border-radius: 4px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                      color: #fff;
                    }
                  }
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: rgb(0, 104, 165);
                    transition-duration: 500ms;
                  }
                }
              }
            }
          }
        }
        .block-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 12px 0px;
          .btn-add-data {
            display: flex;
            width: fit-content;
            padding: 4px 8px;
            box-shadow: 0px 4px 4px #00000040;
            background: #92b557;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;

            i {
              margin-left: 12px;
              color: #fff;
            }

            &:hover {
              cursor: pointer;
              transition-duration: 500ms;
              background: #546832;
            }
          }
        }
      }
    }
  }
}
</style>
