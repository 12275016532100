import axios from "axios";

const state = {
    scoreNameByid: [],
    varieties: [],
    positions: [],
    evaluations: [],
    scoreCategories: null,
    varietiesSelector: [],
    varietiesInfo: [],
    coffeeProvinces: [],
    yearVariety: [],
    selectVarietyDate: [],
    coffeespotVarietie: [],
    coffeeSpot: [],
};

const getters = {
    scoreNameByid: (state) => state.scoreNameByid,
    varieties: (state) => state.varieties,
    positions: (state) => state.positions,
    evaluations: (state) => state.evaluations,
    scoreCategories: (state) => state.scoreCategories,
    allVarietiesSelector: (state) => state.varietiesSelector,
    allvarietiesInfo: (state) => state.varietiesInfo,
    allCoffeeProvinces: (state) => state.coffeeProvinces,
    allYearVariety: (state) => state.yearVariety,
    allSelectVarietyDate: (state) => state.selectVarietyDate,
    allCoffeespotVarietie: (state) => state.coffeespotVarietie,
    allCoffeeSpot: (state) => state.coffeeSpot,
};

const actions = {
    // coffee Spot
    async creatCoffeeSpot({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/coffeespot/create`, body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("newCoffeeSpot", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async updateCoffeeSpotByid({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/coffeespot/update/${body.id}`, body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("updateCoffeeSpot", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getCoffeeSpot({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .post(filter.pagiurl, filter)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("setCoffeeSpot", response.data.message.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async deleteCoffeeSpot({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/coffeespot/delete/${id}`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("removeCoffeeSpot", id);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async returnOldDataCoffeeSpot({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/coffeespot/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataCoffeeSpot",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getAllCoffeeSpot({ commit }, filter) {
        const response = await axios.post("/api/all-coffeespot", filter);
        return response;
    },

    async getAllCoffeeSpotYear({ commit }) {
        const response = await axios.get("/api/evaluation-selector/year");
        return response;
    },

    async getAllVarietyYear({ commit }, id) {
        const response = await axios.get(`/api/coffeespot-selector/year/${id}`);
        return response;
    },
    async getAllVarietyDate({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/api/coffeespot-selector/yearandid/${filter.id}/${filter.year}`
                )
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "setAllSelectVarietyDate",
                            response.data.message
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getAllCoffeeSpotProvinces({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/coffeespot-selector/provinces`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("setAllCoffeeProvinces", response.data.message);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getVarietyInfo({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/variety-info/${id}`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("setallvarietiesInfo", response.data.message);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getVarietiesSelector({ commit }, cosp_id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/varieties-selector/${cosp_id}`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        if (response.data.message.length > 0) {
                            commit(
                                "setallVarietiesSelector",
                                response.data.message
                            );
                        } else {
                            swal.fire({
                                title: "Data not found",
                                text: "",
                                icon: "warning",
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "OK, back to map",
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    window.open("/location-info", "_self");
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getVarietyAllYear({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/variety-info/${id}`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("setAllYearVariety", response.data.message);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // varity api
    async getVarieties({ commit }, filter) {
        return await axios.post(filter.pagiurl, filter).then((response) => {
            commit("setAllVarieties", response.data.message.data);
            return response.data;
        });
    },
    async createVarieties({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/variety/create", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("addVarieties", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async updateVarieties({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/variety/update/" + body.id, body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("updateVarieties", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async returnOldDataVarieties({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/variety/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataVarieties",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async deleteVarieties({ commit }, id) {
        return await axios
            .post("/api/variety/delete/" + id)
            .then((response) => {
                commit("deleteVarieties", id);
                return response.data;
            });
    },

    // positions api
    async getPositions({ commit }, filter) {
        return await axios.post(filter.pagiurl, filter).then((response) => {
            commit("setAllPositions", response.data.message.data);
            return response.data;
        });
    },
    async createPositions({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/position/create", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("addPositions", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async returnOldDataPositions({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/position/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataPositions",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async updatePositions({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/position/update/" + body.id, body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("updatePositions", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async deletePositions({ commit }, id) {
        return await axios
            .post("/api/position/delete/" + id)
            .then((response) => {
                commit("deletePositions", id);
                return response.data;
            });
    },

    // Evaluation api
    async getEvaluations({ commit }, filter) {
        return await axios.post(filter.pagiurl, filter).then((response) => {
            commit("setAllEvaluations", response.data.message.data);
            return response.data;
        });
    },
    async createEvaluation({ commit }, body) {
        return await axios
            .post("/api/evaluation/create", body)
            .then((response) => {
                commit("addEvaluation", response.data.new_data);
                return response.data;
            });
    },
    async updateEvaluation({ commit }, body) {
        return await axios
            .post("/api/evaluation/update/" + body.id, body)
            .then((response) => {
                commit("updateEvaluation", response.data.new_data);
                return response.data;
            });
    },
    async deleteEvaluation({ commit }, id) {
        return await axios
            .post("/api/evaluation/delete/" + id)
            .then((response) => {
                commit("deleteEvaluation", id);
                return response.data;
            });
    },

    //Coffeespot Varietie
    async getCoffeespotVarietie({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .post(filter.pagiurl, filter)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "setCoffeespotVarietie",
                            response.data.message.data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async crateCoffeespotVarietie({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/coffeespot-varietie/create`, data)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("newCoffeespotVarietie", response.data.new_data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async updateCoffeespotVarietie({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `/api/coffeespot-varietie/update/${data.varieties_id}`,
                    data
                )
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "updateCoffeespotVarietie",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async returnOldDataCoffeespotVarietie({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/coffeespot-varietie/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataCoffeespotVarietie",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async deleteCoffeespotVarietie({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/coffeespot-varietie/delete/${id}`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("removeCoffeespotVarietie", id);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    //score-categories
    async createScoreCategories({ commit }, body) {
        const response = await axios.post(`/api/score-category/create`, body);
        return response;
    },

    async deleteScoreCategories({ commit }, id) {
        const response = await axios.post(`/api/score-category/delete/${id}`);
        return response;
    },

    async updateScoreCategories({ commit }, data) {
        const response = await axios.post(
            `/api/score-category/update/${data.id}`,
            data
        );
        return response;
    },

    async returnOldDataScoreCategories({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/score-category/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataScoreCategories",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getScoreCategories({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/score-category`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "setAllScoreCategories",
                            response.data.message.data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    //score-name
    async createScoreName({ commit }, body) {
        const response = await axios.post(`/api/score-name/create`, body);
        return response;
    },

    async getScoreName({ commit }, scca_id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/score-name/byid/${scca_id}`)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit("scoreNameByid", response.data.message.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async returnOldDataScoreName({ commit }, body) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/score-name/return-old-data", body)
                .then((response) => {
                    resolve(response);
                    if (response.data.success) {
                        commit(
                            "addReturnOldDataScoreName",
                            response.data.new_data
                        );
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async deleteScoreName({ commit }, id) {
        const response = await axios.post(`/api/score-name/delete/${id}`);
        return response;
    },

    async updateScoreName({ commit }, data) {
        const response = await axios.post(
            `/api/score-name/update/${data.id}`,
            data
        );
        return response;
    },

    async updateSequencer({ commit }, data) {
        const response = await axios.post(`/api/Sequencer/ScoreCategor`, data);
        return response;
    },

    async updateScoreNameSeq({ commit }, data) {
        const response = await axios.post(`/api/Sequencer/ScoreNameSeq`, data);
        return response;
    },

    //score-point
    async createScore({ commit }, body) {
        const response = await axios.post(`/api/score/create`, body);
        return response;
    },
    async getScoreByEvalId({ commit }, body) {
        const response = await axios.get(`/api/score/edit/` + body.id);
        return response;
    },
};

const mutations = {
    scoreNameByid: (state, payload) => (state.scoreNameByid = payload),
    addReturnOldDataScoreName: (state, payload) =>
        state.scoreNameByid.unshift(payload),
    setAllVarieties: (state, payload) => (state.varieties = payload),
    addVarieties: (state, payload) => state.varieties.unshift(payload),
    updateVarieties(state, payload) {
        state.varieties = state.varieties.map((variety) => {
            if (variety.id === payload.id) {
                return Object.assign({}, variety, payload);
            }
            return variety;
        });
    },
    addReturnOldDataVarieties: (state, payload) =>
        state.varieties.unshift(payload),
    deleteVarieties: (state, id) =>
        (state.varieties = state.varieties.filter(
            (variety) => variety.id !== id
        )),
    setAllPositions: (state, payload) => (state.positions = payload),
    addPositions: (state, payload) => state.positions.unshift(payload),
    addReturnOldDataPositions: (state, payload) =>
        state.positions.unshift(payload),

    updatePositions(state, payload) {
        state.positions = state.positions.map((variety) => {
            if (variety.id === payload.id) {
                return Object.assign({}, variety, payload);
            }
            return variety;
        });
    },
    deletePositions: (state, id) =>
        (state.positions = state.positions.filter(
            (variety) => variety.id !== id
        )),
    setAllEvaluations: (state, payload) => (state.evaluations = payload),
    addEvaluation: (state, payload) => state.evaluations.unshift(payload),
    updateEvaluation(state, payload) {
        state.evaluations = state.evaluations.map((evaluate) => {
            if (evaluate.id === payload.id) {
                return Object.assign({}, evaluate, payload);
            }
            return evaluate;
        });
    },
    deleteEvaluation: (state, id) =>
        (state.evaluations = state.evaluations.filter(
            (evaluate) => evaluate.id !== id
        )),
    setAllScoreCategories: (state, payload) =>
        (state.scoreCategories = payload),
    addReturnOldDataScoreCategories: (state, payload) =>
        state.scoreCategories.unshift(payload),
    setallVarietiesSelector: (state, payload) =>
        (state.varietiesSelector = payload),
    setallvarietiesInfo: (state, payload) => (state.varietiesInfo = payload),
    setAllCoffeeProvinces: (state, payload) =>
        (state.coffeeProvinces = payload),
    setAllYearVariety: (state, payload) => (state.yearVariety = payload),
    setAllSelectVarietyDate: (state, payload) =>
        (state.selectVarietyDate = payload),
    setCoffeespotVarietie: (state, payload) =>
        (state.coffeespotVarietie = payload),
    newCoffeespotVarietie: (state, payload) =>
        state.coffeespotVarietie.unshift(payload),
    updateCoffeespotVarietie(state, payload) {
        state.coffeespotVarietie = state.coffeespotVarietie.map((data) => {
            if (data.id === payload.id) {
                return Object.assign({}, data, payload);
            }
            return data;
        });
    },
    addReturnOldDataCoffeespotVarietie: (state, payload) =>
        state.coffeespotVarietie.unshift(payload),
    removeCoffeespotVarietie: (state, id) =>
        (state.coffeespotVarietie = state.coffeespotVarietie.filter(
            (data) => data.id !== id
        )),

    setCoffeeSpot: (state, payload) => (state.coffeeSpot = payload),
    updateCoffeeSpot(state, payload) {
        state.coffeeSpot = state.coffeeSpot.map((data) => {
            if (data.id === payload.id) {
                return Object.assign({}, data, payload);
            }
            return data;
        });
    },
    removeCoffeeSpot: (state, id) =>
        (state.coffeeSpot = state.coffeeSpot.filter((data) => data.id !== id)),
    newCoffeeSpot: (state, payload) => state.coffeeSpot.unshift(payload),
    addReturnOldDataCoffeeSpot: (state, payload) =>
        state.coffeeSpot.unshift(payload),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
