<template>
  <div class="container-search">
    <div class="search-box">
      <input :placeholder="translate('menu.search')" type="text" v-model="textSearch" />
    </div>
    <div class="i-search">
      <i class="fas fa-search" @click="search"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textSearch: "",
    };
  },

  methods: {
    search() {
      this.$emit("search", this.textSearch);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-search {
  max-width: 300px;
  width: 100%;
  height: 50px;
  left: 156px;
  top: 125px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .search-box {
    width: 100%;
    height: 100%;
    input {
      border: none;
      padding: 12px 0 12px 16px;
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      font-weight: 500;
      font-size: 20px;
      color: #000000;
      box-shadow: none !important;

      :-webkit-autofill {
        color: #fff !important;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .i-search {
    height: 100%;
    display: flex;
    align-items: center;

    :hover {
      cursor: pointer;
      background: #7e7e7e80;
    }

    i {
      // height: 100%;
      padding: 12px;
      font-weight: 500;
      font-size: 25px;
      color: #000000;
    }
  }
}
</style>
