import { gvar } from "./gVar";
import Basic from "./permission/Basic";
// Sidebar
import Location from "./homePage/Location.vue";
import VarietyInfo from "./homePage/VarietyInfo.vue";
import ManageCoffeeSpot from "./manageCoffee/ManageCoffeeSpot.vue";
import ManageVariety from "./manageVariety/ManageVariety.vue";
import ManageLocation from "./manageLocation/Location.vue";
import CoffeeVariety from "./manageMasterData/coffeeVariety/CoffeeVariety.vue";
import District from "./manageMasterData/district/District.vue";
import Processing from "./manageMasterData/processing/Processing.vue";
import Province from "./manageMasterData/province/Province.vue";
import ScoreCategories from "./manageMasterData/ScoreCategories/ScoreCategories.vue";
import ManageAdminInfo from "./manageAdminInfo/ManageAdminInfo.vue";
import ImportExcel from "./importExcel/ImportExcel.vue";
import Position from "./position/Position.vue";
import Evaluation from "./manageVariety/score/Evaluation";

let ur = document.querySelector("meta[name='ur']");
let userRole;
if (ur) {
    userRole = ur.getAttribute("content");
}
let ur_id = document.querySelector("meta[name='ur_id']");
let userRoleId;
if (ur_id) {
    userRoleId = ur_id.getAttribute("content");
}

let userPermission = document.querySelector("meta[name='urs']");
var permit = null;
if (userPermission) {
    let userR = userPermission.getAttribute("content"); //get content from meta
    permit = new Basic(userR);
}

let cus_status = document.querySelector("meta[name='cust_status']");
let cust_status = null;

if (cus_status) {
    cust_status = cus_status.getAttribute("content");
}
cust_status = parseInt(cust_status);

let b = document.querySelector("meta[name='band']");
let band;
if (b) {
    band = b.getAttribute("content");
}

const routes = [
    {
        path: "/location-info",
        name: "location-info",
        component: Location,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            next();
        }
    },
    {
        path: "/variety-info/:location/:name/:year/:varietyid",
        name: "variety-info",
        component: VarietyInfo,
        props: true,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            next();
        }
    },
    {
        path: "/manage-coffee-spot/",
        name: "manage-coffee-spot",
        component: ManageCoffeeSpot,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewLocations()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/manage-variety",
        name: "manage-variety",
        component: ManageVariety,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewVarieties()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/manage-location",
        name: "manage-location",
        component: ManageLocation,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewLocations()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/coffee-variety",
        name: "coffee-variety",
        component: CoffeeVariety,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewMasterData()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/province",
        name: "province",
        component: Province,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewMasterData()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/district",
        name: "district",
        component: District,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewMasterData()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/score-categories",
        name: "score-categories",
        component: ScoreCategories,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewMasterData()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/processing",
        name: "processing",
        component: Processing,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewProcess()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/evaluation/:cova_id",
        name: "evaluation",
        component: Evaluation,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewMasterData()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/manage-admin-info",
        name: "manage-admin-info",
        component: ManageAdminInfo,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewUsers()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    },
    {
        path: "/import-excel",
        name: "import-excel",
        component: ImportExcel,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            next();
        }
    },
    {
        path: "/position",
        name: "position",
        component: Position,
        meta: {
            requiesAuth: true
        },
        beforeEnter(to, from, next) {
            if (permit.isViewPosition()) {
                next();
            } else {
                swal.fire(
                    "",
                    window.tranlate("error.invalid-permission"),
                    "warning"
                );
            }
        }
    }
];

export default routes;
