
<template>
  <div>
    <h1>District</h1>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: [],
  components: {},
  mounted() {},
  data() {
    return {};
  },
  computed: mapGetters([]),
  watch: {},
  methods: {
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
</style>
