// import Vue from "Vue";
import Basic from "../permission/Basic";
import moment from "moment";
import { gvar } from "../gVar";
//which band is it?

let uid = document.querySelector("meta[name='u']");
var auth_id;
if (uid) {
    auth_id = uid.getAttribute("content");
}

let appName = document.querySelector("meta[name='app_name']");
var aN;
if (appName) {
    aN = appName.getAttribute("content");
}

export default {
    install(Vue, opts) {
        Vue.prototype.$app_name = aN;
        Vue.prototype.$disabledFutureDays = function(value) {
            return value > new Date();
        };

        Vue.prototype.$base_domain = location.hostname.trim();
        Vue.prototype.$gvar = gvar;

        Vue.prototype.$sortedClass = function(key) {
            return this.sort.key === key
                ? `sorted ${this.sort.isAsc ? "asc" : "desc"}`
                : "";
        };
        Vue.prototype.$sortBy = function(key) {
            this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
            this.sort.key = key;
        };

        Vue.prototype.$closeTap = function() {
            let hasPath = this.$cookies.get(window.location.pathname);
            if (parseInt(hasPath) > 0) {
                this.$cookies.remove(window.location.pathname);
                window.close();
            } else {
                this.$cookies.set(
                    window.location.pathname,
                    hasPath ? parseInt(hasPath) + 1 : 1
                );
                location.reload();
            }
        };

        Vue.prototype.$init_sdate_formt = function(date_in) {
            let d = "";
            d = new Date(moment(date_in).format("YYYY/MM/DD"));
            return d;
        };
        Vue.prototype.$init_edate_formt = function(date_in) {
            let d = "";
            d = new Date(moment(date_in).format("YYYY/MM/DD HH:mm"));
            return d;
        };

        Vue.prototype.$typeFormat = function() {
            let str = "date";
            return str;
        };

        Vue.prototype.$isIframe = window === window.parent ? false : true;

        Vue.prototype.$imiTokenURL = "/api/imi/customer-login/" + auth_id;

        Vue.prototype.$removeDecimal = function(value) {
            let result = 0;
            if (value) {
                if (typeof value === "string" || value instanceof String) {
                    let value = parseFloat(value);
                }
                result = parseInt(value);
            }
            let num_parts = result.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        };
        Vue.prototype.$thousands_separators = function(num) {
            if (num) {
                var num_parts = num.toString().split(".");
                num_parts[0] = num_parts[0].replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                );
                return num_parts.join(".");
            }
        };

        Vue.prototype.$user_status = {
            CUST_STATUS_NOT_CUSTOMER: 0,
            CUST_STATUS_VERIFY: 1,
            CUST_STATUS_FIRST_TIME: 2,
            CUST_STATUS_CHANGE_PASS: 3,
            CUST_STATUS_NEW_CUSTOMER: 4,
            CUST_STATUS_OLD_CUSTOMER: 5,
            CUST_STATUS_CANNOT_CREATED_IN_AGENT: 6
        };

        let logtoken = document.querySelector("meta[name='login_token']");
        let login_token;
        if (logtoken) {
            login_token = logtoken.getAttribute("content");
        }
        Vue.prototype.$login_token = login_token;

        // user role name
        let userRoleN = document.querySelector("meta[name='urn']");
        let userRoleName = null;
        if (userRoleN) {
            userRoleName = userRoleN.getAttribute("content"); //get content from meta
        }
        Vue.prototype.$userRoleName = userRoleName;
        // user role name

        let cus_status = document.querySelector("meta[name='cust_status']");
        let cust_status = null;

        if (cus_status) {
            cust_status = cus_status.getAttribute("content");
        }
        Vue.prototype.$cust_status = parseInt(cust_status);

        let ur = document.querySelector("meta[name='ur']");
        let userRole;
        if (ur) {
            userRole = ur.getAttribute("content");
        }
        Vue.prototype.$userRole = userRole;

        let userPermission = document.querySelector("meta[name='urs']");
        if (userPermission) {
            let userR = userPermission.getAttribute("content"); //get content from meta
            Vue.prototype.$permission = new Basic(userR);
        }

        Vue.prototype.$setFormToEdit = function(param, form) {
            Object.keys(param).forEach(function(k, index) {
                Object.keys(form).forEach(function(key, index) {
                    if (key == k) {
                        form[key] = param[k];
                    }
                });
            });
        };
        Vue.prototype.$resetForm = function(errors, form) {
            var self = this;
            Object.keys(errors).forEach(function(key, index) {
                errors[key] = "";
            });
            Object.keys(form).forEach(function(key, index) {
                if (form[key] != "id") {
                    form[key] = "";
                }
            });
        };
        Vue.prototype.$cust_resetForm = function(errors, form) {
            var self = this;
            Object.keys(errors).forEach(function(key, index) {
                errors[key] = "";
            });
            Object.keys(form).forEach(function(key, index) {
                if (key != "password") {
                    if (key != "id") {
                        form[key] = "";
                    }
                }
            });
        };
        Vue.prototype.$resetErrors = function(errors) {
            var self = this;
            Object.keys(errors).forEach(function(key, index) {
                errors[key] = "";
            });
        };
        Vue.prototype.$getOverallIndex = function(index, pagination) {
            return (
                pagination.current_page * pagination.per_page -
                pagination.per_page +
                index +
                1
            );
        };

        Vue.prototype.$removeFunction = function(myObjects, prop, valu) {
            return myObjects.filter(function(val) {
                return val[prop] !== valu;
            });
        };

        Vue.prototype.$get_current_page_number = function(url_path) {
            let path = url_path.split("?page=");
            let num = null;
            if (path.length > 1) {
                num = path[1];
            }
            return num;
        };

        Vue.prototype.$titleCase = function(str) {
            str = str.toLowerCase().split(" ");
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(" ");
        };

        Vue.prototype.$errorString = function(errorStr) {
            var self = this;
            let eMsg = null;
            if (typeof errorStr === "string" || errorStr instanceof String) {
                eMsg = errorStr;
            }
            self.errorMsg = eMsg;
        };
        Vue.prototype.$errorMsg = function(errorStr) {
            let eMsg = null;
            if (typeof errorStr === "string" || errorStr instanceof String) {
                eMsg = errorStr;
            }
            return eMsg;
        };
        Vue.prototype.$validateMsg = function(errorObj) {
            var self = this;
            Object.keys(self.errors).forEach(function(key, index) {
                self.errors[key] = "";
            });
            Object.keys(errorObj).forEach(function(key, index) {
                self.errors[key] = errorObj[key][0];
            });
        };
        Vue.prototype.$errorBorder = function(name) {
            return this.errors[name] ? "border-danger" : "";
        };
        Vue.prototype.$errorText = function(name) {
            return this.errors[name] ? "text-danger" : "";
        };

        Vue.prototype.$paginate = function(page) {
            return {
                current_page: page.current_page,
                last_page: page.last_page,
                from_page: page.from,
                to_page: page.to,
                total_page: page.total,
                path_page: page.path + "?page=",
                first_link: page.first_page_url,
                last_link: page.last_page_url,
                prev_link: page.prev_page_url,
                next_link: page.next_page_url,
                per_page: page.per_page
            };
        };

        Vue.filter("upText", function(text) {
            text = text.toString();
            return text.charAt(0).toUpperCase() + text.slice(1);
        });

        Vue.filter("slashDateTime", function(created) {
            if (created && created != "0000-00-00 00:00:00") {
                return moment(created).format("DD/MM/YYYY HH:mm");
            }
        });
        Vue.filter("dashDateTime", function(created) {
            if (created && created != "0000-00-00 00:00:00") {
                return moment(created).format("DD-MM-YYYY HH:mm");
            }
        });

        Vue.filter("dashDate", function(created) {
            if (created && created != "0000-00-00 00:00:00") {
                return moment(created).format("DD-MM-YYYY");
            }
        });

        Vue.filter("slashDate", function(created) {
            if (created && created != "0000-00-00 00:00:00") {
                return moment(created).format("DD/MM/YYYY");
            }
        });

        Vue.filter("mDate", function(created) {
            if (created && created != "0000-00-00 00:00:00") {
                return moment(created).format("MMM d, YYYY");
            }
        });

        Vue.filter("dmyDate", function(date) {
            if (date && date != "0000-00-00 00:00:00") {
                return moment(date).format("D MMM, YYYY");
            }
        });

        Vue.filter("mmTime", function(created) {
            return moment(created).format("HH:mm");
        });

        Vue.filter("mTime", function(time) {
            let arr = time.split(":");
            let hour = arr[0];
            let minute = arr[1];
            return hour + ":" + minute;
        });
        Vue.filter("tTime", function(time) {
            let d = new Date("October 13, 2020 " + time);
            var h = d.getHours();
            var m = d.getMinutes();
            return addZero(h) + ":" + addZero(m);
        });

        // function addZero(i) {
        //     if (i < 10) {
        //         i = "0" + i;
        //     }
        //     return i;
        // }

        // -------------language----------------
        let langSession = document.querySelector("meta[name='lang']");
        if (langSession) {
            Vue.prototype.$language = langSession.getAttribute("content");
        }
        // -------------language----------------

        // -------------auth id----------------
        Vue.prototype.$auth_id = auth_id;
        // -------------auth id---------------
    }
};
