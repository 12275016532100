<template>
  <div>
    <div @click="open()">Forgot password?</div>
    <ForgetPasswordModal ref="ForgetPasswordModal" />
  </div>
</template>
<script>
import ForgetPasswordModal from "./ForgetPasswordModal.vue";

export default {
  components: { ForgetPasswordModal },
  mounted() {},
  data() {
    return {};
  },

  watch: {},
  methods: {
    open() {
      this.$refs.ForgetPasswordModal.openModal();
    },
  },
};
</script>

<style lang="sass">
</style>
