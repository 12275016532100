<template>
	<div>
		<div class="block-step">
			<div class="block-reduce-increase" v-show="defaultSizeList > 100" @click="btnSizeList(defaultSizeList - 100)">
				<i class="fas fa-angle-left reduce-increase"></i>-100
			</div>
			<div class="block-reduce-increase" v-show="defaultSizeList > 10" @click="btnSizeList(defaultSizeList - 10)">
				<i class="fas fa-angle-left reduce-increase"></i>-10
			</div>
			<input type="number" class="size-list" :value="defaultSizeList" @change="checkList">
			<div class="block-reduce-increase" v-show="defaultSizeList < totalList"  @click="btnSizeList(defaultSizeList + 10)">
				+10<i class="fas fa-angle-right reduce-increase"></i>
			</div>
			<div class="block-reduce-increase" v-show="totalList > defaultSizeList+100" @click="btnSizeList(defaultSizeList + 100)">
				+100<i class="fas fa-angle-right reduce-increase"></i>
			</div>
			<div class="block-reduce-increase" v-show="defaultSizeList < totalList" @click="btnSizeList(totalList)">
				All<i class="fas fa-angle-double-right reduce-increase"></i>
			</div>
			<h6 style="margin: 0px; color: #747474; font-size: 12px;">{{`Page ${defaultSizeList} of ${totalList}`}}</h6>
		</div>
	</div>
</template>

<script>
export default {
	props: ['totalList', 'defaultSizeList'],
	data() {
		return {
		}
	},
	watch: {
		// defaultSizeList() {
		// 	this.defaultSizeList = this.defaultSizeList
		// }
	},

	methods: {
		btnSizeList(data) {
			if (data < 10) {
				data = 10
			}
			if (data > this.totalList) {
				data = this.totalList
			}
			console.log('defaultSizeList', data);
			this.$emit('page', data)
		},
		checkList($event) {
			let data = $event.target.valueAsNumber
			if (data < 10) {
				data = 10
			}
			if (data > this.totalList) {
				data = this.totalList
			}
			this.$emit('page', data)
		}
	},
}
</script>

<style lang="scss" scoped>
.block-step {
	display: flex;
	margin: 8px 16px;
	align-items: center;
	height: 36px;

	.block-reduce-increase {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0px 4px;
		background: #92b558;
		border-radius: 4px;
		border: 1px solid #000;
		margin: 0px 4px;

		&:hover {
			transition-duration: 500ms;
			background: #536833;
			cursor: pointer;
		}

		.reduce-increase {
			font-size: 24px;
		}
	}
	.size-list {
		margin: 0px 4px;
		width: 64px;
		height: 100%;
		text-align: center;
		border-radius: 4px;
		border: 1px solid #000;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
</style>