
let user = document.querySelector("meta[name='u']")
var authUser = null;

if (user) {
    authUser = user.getAttribute('content'); //get content from meta
}


export function getLocalUser() {
    const userStr = authUser;
    if (!userStr) {
        return null;
    }

    return JSON.parse(userStr);

}

