<template>
  <div>
    <div
      data-keyboard="false"
      data-backdrop="static"
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
      ref="EvaluationModal"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{
                editMode
                  ? translate("menu.edit_coffeee_varity_score_and_image")
                  : translate("menu.add_coffeee_varity_score_and_image")
              }}
            </h5>
            <div class="container-input">
              <div class="box-input w-haft">
                <div for="scored_date" class="title-input is-required">
                  <span class="text-uppercase">{{
                    translate("menu.scored_date")
                  }}</span>
                  <span style="color: #808080; font-size: 14px">
                    {{ translate("menu.Day_Month_Year_AD") }}
                  </span>
                </div>
                <!-- <input
                  @keyup="checkValue('scored_date')"
                  @blur="blurFormatDate('scored_date')"
                  @input="inputFormatDate('scored_date')"
                  type="text"
                  :placeholder="translate('menu.DD_MM_YYYY')"
                  v-model="form.scored_date"
                /> -->
                <!-- <div class="col-sm-2 pl-0 d-flex justify-content-end"> -->
                <date-picker
                  v-model="dateEva"
                  class="w-100"
                  :clearable="true"
                  :lang="$language == 'en' ? 'en' : 'th'"
                  format="DD/MM/YYYY"
                  type="date"
                  :placeholder="translate('menu.DD_MM_YYYY')"
                  popup-class="my_datepicker_popup"
                  @change="dateChange()"
                  :disabled-date="
                    (date) => disabledBeforeTodayAndAfterAWeek(date)
                  "
                ></date-picker>
                <!-- </div> -->
                <div
                  class="text-validate"
                  style="display: none"
                  id="scored_date"
                >
                  {{
                    `${translate("menu.please_enter")} ${translate(
                      "menu.scored_date"
                    )}`
                  }}
                </div>
              </div>
              <div class="box-input w-haft">
                <div for="cup_score" class="title-input is-required">
                  <span class="text-uppercase">{{
                    translate("menu.cup_score")
                  }}</span>
                </div>
                <input
                  @keyup="checkValue('cup_score')"
                  @blur="checkValue('cup_score')"
                  type="text"
                  :placeholder="translate('menu.cup_score')"
                  v-model="form.cup_score"
                />
                <div class="text-validate" style="display: none" id="cup_score">
                  {{
                    `${translate("menu.please_enter")} ${translate(
                      "menu.cup_score"
                    )}`
                  }}
                </div>
              </div>
              <div class="box-input">
                <div
                  for="charact"
                  class="title-input text-uppercase is-required"
                >
                  {{ translate("menu.characteristics") }}
                </div>
                <textarea
                  :placeholder="translate('menu.characteristics')"
                  v-model="form.charact"
                />
                <div class="text-validate" style="display: none" id="charact">
                  {{
                    `${translate("menu.please_enter")} ${translate(
                      "menu.characteristics"
                    )}`
                  }}
                </div>
              </div>
            </div>
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{
                editMode
                  ? translate("menu.edit_photo")
                  : translate("menu.add_photo")
              }}
            </h5>
            <div class="container-input flex_pic">
              <div class="box-input w-haft center">
                <div for="green_bean_photo" class="title-input">
                  {{ translate("menu.green_bean_photo") }}
                </div>
                <label for="upload_before_pic" style="cursor: pointer">
                  <img
                    id="before_pic"
                    class="size_pic"
                    :src="
                      this.img.before_pic == ''
                        ? '/img/error_img.png'
                        : this.img.before_pic
                    "
                  />
                  <input
                    class="addImg"
                    type="file"
                    id="upload_before_pic"
                    style="display: none"
                    @change="setImg('before_pic', $event)"
                  />
                </label>
              </div>
              <div class="box-input w-haft center">
                <div for="green_bean_photo" class="title-input text-uppercase">
                  {{ translate("menu.green_bean_photo") }}
                </div>
                <label for="upload_after_pic" style="cursor: pointer">
                  <img
                    id="after_pic"
                    class="size_pic"
                    :src="
                      this.img.after_pic == ''
                        ? '/img/error_img.png'
                        : this.img.after_pic
                    "
                  />
                  <input
                    class="addImg"
                    type="file"
                    id="upload_after_pic"
                    style="display: none"
                    @change="setImg('after_pic', $event)"
                  />
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="saveData('next')"
                class="btn btn-primary save_next"
              >
                {{ translate("menu.save_next") }}
              </button>
              <button @click="saveData()" class="btn btn-primary save">
                {{ translate("menu2.save") }}
              </button>
              <button
                type="button"
                class="btn btn-outline-info cancel"
                data-dismiss="modal"
              >
                {{ translate("menu2.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { DatePicker },
  mounted() {
    $(this.$refs.EvaluationModal).on("hidden.bs.modal", () => {
      (this.form = {}),
        (this.img = {
          before_pic: "",
          after_pic: "",
        });
    });
  },
  data() {
    return {
      editMode: false,
      form: {},
      img: {
        before_pic: "",
        after_pic: "",
      },
      dateEva: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions(["createEvaluation", "updateEvaluation"]),

    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return (
        date < new Date(today.getTime() - 7300 * 24 * 3600 * 1000) ||
        date > today
      );
    },
    setImg(name, event) {
      // this.img[name] = window.URL.createObjectURL(event.target.files[0])
      this.getBase64(event.target.files[0]).then((res) => {
        this.img[name] = res;
        this.form[name] = res;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    saveData(data) {
      let validateSaveData = true;
      let dataRes = null;
      for (const data of ["scored_date", "cup_score", "charact"]) {
        if (this.form[data] == "" || this.form[data] == undefined) {
          validateSaveData = false;
          this.checkValue(data);
        }
      }
      if (validateSaveData) {
        if (this.editMode) {
          this.form = {
            ...this.form,
          };
          this.updateEvaluation(this.form)
            .then((res) => {
              dataRes = res;
              Fire.$emit("updateData");
              toast.fire({
                position: "top-end",
                icon: "success",
                title: res.message,
              });
              this.closeModal();
              if (data == "next") {
                this.$emit("openAddScore", dataRes.new_data);
              }
            })
            .catch((res) => {
              toast.fire({
                position: "top-end",
                icon: "error",
                title: this.translate("menu.fail"),
              });
            });
        } else if (!this.editMode) {
          this.form = {
            cova_id: window.location.pathname.split("/").pop(),
            ...this.form,
          };
          this.createEvaluation(this.form)
            .then((res) => {
              dataRes = res;
              Fire.$emit("updateData");
              if (res.success == true) {
                toast.fire({
                  position: "top-end",
                  icon: "success",
                  title: res.message,
                });
                this.closeModal();
                if (data == "next") {
                  this.$emit("openAddScore", dataRes.new_data);
                }
              } else {
                toast.fire({
                  position: "top-end",
                  icon: "error",
                  title: this.translate("menu.fail"),
                });
              }
            })
            .catch((res) => {
              toast.fire({
                position: "top-end",
                icon: "error",
                title: this.translate("menu.fail"),
              });
            });
        }
      }
    },
    openModal() {
      this.form = {};
      this.dateEva = "";
      this.editMode = false;
      $(this.$refs.EvaluationModal).modal("show");
    },
    openModalEdit(user) {
      this.form = {};
      this.form = { ...user };
      this.dateEva = new Date(
        moment(this.form.scored_date).format("YYYY-MM-DD HH:mm:ss")
      );
      this.editMode = true;
      this.img.before_pic =
        this.form.before_pic != "" ? `/img/score/${this.form.before_pic}` : "";
      this.img.after_pic =
        this.form.after_pic != "" ? `/img/score/${this.form.after_pic}` : "";
      $(this.$refs.EvaluationModal).modal("show");
    },
    closeModal() {
      $(this.$refs.EvaluationModal).modal("hide");
    },

    checkValue(nameElement) {
      let element = document.getElementById(nameElement);
      if (this.form[nameElement] == undefined) {
        this.form[nameElement] = "";
        element.style.display = "block";
      }
      if (this.form[nameElement] == "") {
        element.style.display = "block";
      }
      if (this.form[nameElement] != "") {
        element.style.display = "none";
      }
    },

    checkStrDate(str, max) {
      if (str.charAt(0) !== "0" || str == "00") {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str =
          num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
            ? "0" + num
            : num.toString();
      }
      return str;
    },

    checkStrDateYear(str) {
      if (str.length == 4) {
        let dateNow = new Date().getFullYear();
        let dateInput = parseInt(str);
        if (dateInput > dateNow || dateInput < dateNow - 50) {
          str = dateNow.toString();
        }
      }
      return str;
    },

    dateChange() {
      this.form.scored_date = this.dateEva
        ? moment(this.dateEva).format("YYYY-MM-DD")
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background: #ebebeb;
  padding: 38px 32px;
  .modal-header {
    display: block;
    position: relative;
    border: none;
    padding: 0px;

    .modal-title {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #8c8c8c;
    }
    .container-input {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 16px;

      .box-input {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        &.w-haft {
          width: 48%;
        }
        &.center {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .title-input {
          color: #2c2c2c;
          &.is-required {
            &::after {
              margin-left: 1px;
              color: #ff0000;
              content: "*";
            }
          }
        }
        input {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          :-webkit-autofill {
            color: #fff !important;
          }
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: text;
          }
        }
        img {
          z-index: 2;
          pointer-events: none;
          border-radius: 5px;
        }
        .addImg {
          z-index: 1;
          margin-top: 23px;
          position: absolute;
          content: none;
          width: 140px;
          height: 140px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          &:hover {
            transition-duration: 500ms;
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
          }
        }
        textarea {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          width: 100%;
          height: 96px;
        }
        select {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          &:hover {
            cursor: text;
          }
        }
        .date {
          border: none;
          padding: 4px 8px;
          background: #ffffff;
          box-shadow: 0px 4px 4px #00000040;
          border-radius: 5px;
          height: 40px;
          &:hover {
            cursor: text;
          }
        }
      }
    }
    .modal-footer {
      display: flex;
      flex-direction: column;
      border-top: none;

      .btn {
        width: 298px;
        border-radius: 20px;
        font-size: 20px;
        @media (max-width: 500px) {
          width: 100%;
        }

        &.save_next {
          background: #617c36;
          color: #fff;
          &:hover {
            transition-duration: 500ms;
            background: #415324;
          }
        }
        &.save {
          color: #617c36;
          border: 1px solid #617c36;
          background: #617c3600;
          &:hover {
            transition-duration: 500ms;
            background: rgba(97, 124, 54, 0.2);
          }
        }
        &.cancel {
          border: none;
          color: #000;
          &:hover {
            transition-duration: 500ms;
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .btn-close-modal {
      border: none;
      position: absolute;
      top: 0px;
      right: 0px;
      background: none;
      margin: 16px;

      i {
        font-size: 20px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background: var(--bg-content-default);
      margin: 8px 0;
    }
    .box-name-lotto {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .container-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .box-btn {
      margin: 8px 18px;

      .btn {
        padding: 8px 12px;
        box-shadow: 0px 4px 4px #00000040;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;

        &.succ {
          background: #92b558;

          &:hover {
            transition-duration: 500ms;
            background: #394920;
          }
        }
        &.err {
          background: #e03737;

          &:hover {
            transition-duration: 500ms;
            background: #972323;
          }
        }
      }
    }
  }
}

.text-validate {
  color: #ff0000;
  font-size: 12px;
  margin-left: 4px;

  &.d-none {
    display: none;
  }
}
.flex_pic {
  flex-direction: row;
  @media (max-width: 389px) {
    flex-direction: column !important;
    align-items: center;
  }
}
.size_pic {
  max-width: 300px;
  @media (max-width: 991px) {
    max-width: 200px;
  }
  @media (max-width: 500px) {
    max-width: 150px;
  }
}
</style>
