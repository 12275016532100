<template>
  <div>
    <section>
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-5">
            <h1>
              {{ translate("menu2.process") }}
            </h1>
          </div>
          <div class="col-sm-7 d-flex justify-content-end align-items-center">
            <div class="card-tools">
              <div class="input-group input-group-sm">
                <div class="input-group">
                  <button
                    @click.prevent="clearSearch()"
                    class="btn btn-info reset_search btn-sm"
                    v-if="isInputSearch"
                  >
                    <i class="fas fa-undo"></i>
                  </button>
                  <input
                    type="text"
                    name="table_search"
                    class="form-control float-right"
                    :placeholder="translate('menu.search')"
                    v-model="filter.search"
                  />

                  <div class="input-group-append">
                    <button
                      type="button"
                      @click="searchKey()"
                      class="btn btn-primary"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <!-- /.row -->
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="d-flex">
                  <div class="mr-auto change_list">
                    <div class="dropdown">
                      <button
                        class="btn btn-default tb_row_drop dropdown-toggle"
                        type="button"
                        id="showRow"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="tb_row_text"
                          >{{ translate("menu2.show") }} {{ filter.listNum }}
                          {{ translate("menu2.row") }}</span
                        >
                        <span class="icon-caret drop_caret"></span>
                      </button>
                      <div
                        class="
                          dropdown-menu
                          dropdown-menu-right
                          dropdown-menu-lg-left
                          show_list_dropdown
                        "
                        aria-labelledby="showRow"
                      >
                        <a class="dropdown-item" @click="changeList(5)">5</a>
                        <a class="dropdown-item" @click="changeList(10)">10</a>
                        <a class="dropdown-item" @click="changeList(50)">50</a>
                        <a class="dropdown-item" @click="changeList(100)"
                          >100</a
                        >
                        <a class="dropdown-item" @click="changeList(200)"
                          >200</a
                        >
                        <a class="dropdown-item" @click="changeList(1000)"
                          >1000</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="title">
                    <button
                      class="btn btn-loc"
                      @click="openModal()"
                      v-if="$permission.isCreateProcess()"
                    >
                      <i class="fas fa-plus"></i>
                      {{ translate("menu2.add") }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-striped
                      sortTable
                    "
                  >
                    <thead>
                      <tr>
                        <th
                          width="5%"
                          :class="$sortedClass('order')"
                          @click="$sortBy('order')"
                        >
                          {{ translate("menu2.order") }}
                        </th>
                        <th
                          :class="$sortedClass('name')"
                          @click="$sortBy('name')"
                        >
                          {{ translate("menu2.name") }}
                        </th>

                        <th
                          width="8%"
                          v-if="
                            $permission.isEditProcess() ||
                            $permission.isDeleteProcess()
                          "
                        >
                          {{ translate("menu.edit") }}/{{
                            translate("menu.delete")
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="data in sortedItems" :key="data.id">
                        <td>
                          {{ data.order }}
                        </td>
                        <td>
                          <div>
                            <span class="view_val">{{ data.name }}</span>
                          </div>
                        </td>
                        <td
                          style="display: flex"
                          v-if="
                            $permission.isEditProcess() ||
                            $permission.isDeleteProcess()
                          "
                        >
                          <button
                            v-if="$permission.isEditProcess()"
                            class="btn btn-action"
                            @click="toEditModal(data)"
                          >
                            <i class="fas fa-edit btn-edit"></i>
                          </button>
                          <button
                            v-if="$permission.isDeleteProcess()"
                            class="btn btn-action"
                            @click="toDelete(data.id)"
                          >
                            <i class="fas fa-trash-alt btn-delete"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- pagination -->
                <div class="row">
                  <div class="col-md-8">
                    <nav>
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: !pagination.first_link }"
                        >
                          <a
                            href="#"
                            @click="getData(pagination.first_link)"
                            class="page-link"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: !pagination.prev_link }"
                        >
                          <a
                            href="#"
                            @click="getData(pagination.prev_link)"
                            class="page-link"
                          >
                            <span aria-hidden="true">&#8249;</span>
                          </a>
                        </li>
                        <template
                          v-for="(pageNumber, index) in pagination.last_page"
                        >
                          <li
                            :key="index"
                            v-if="
                              Math.abs(pageNumber - pagination.current_page) <
                                3 ||
                              pageNumber === pagination.last_page ||
                              pageNumber == 1
                            "
                            class="page-item"
                            :class="{
                              active: pagination.current_page == pageNumber,
                            }"
                          >
                            <a
                              href="#"
                              @click="
                                getData(pagination.path_page + pageNumber)
                              "
                              class="page-link pagi_link"
                              :class="{
                                last:
                                  pageNumber == pagination.last_page &&
                                  Math.abs(
                                    pageNumber - pagination.current_page
                                  ) > 3,
                                first:
                                  pageNumber == 1 &&
                                  Math.abs(
                                    pageNumber - pagination.current_page
                                  ) > 3,
                              }"
                              >{{ pageNumber }}</a
                            >
                          </li>
                        </template>

                        <li
                          class="page-item"
                          :class="{ disabled: !pagination.next_link }"
                        >
                          <a
                            href="#"
                            @click="getData(pagination.next_link)"
                            class="page-link"
                          >
                            <span aria-hidden="true">&#8250;</span>
                          </a>
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: !pagination.last_link }"
                        >
                          <a
                            href="#"
                            @click="getData(pagination.last_link)"
                            class="page-link"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="col-md-4 d-flex justify-content-end">
                    <div>
                      {{ translate("menu2.page") }}:
                      {{ pagination.from_page }} - {{ pagination.to_page }}
                      {{ translate("menu2.total") }}:
                      {{ pagination.total_page }}
                    </div>
                  </div>
                </div>
                <!-- pagination -->
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <ProcessingModal ref="ProcessingModal" />
  </div>
</template>
<script>
import ProcessingModal from "./ProcessingModal";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ProcessingModal },
  data() {
    return {
      api_url: "/api/processes",
      pagination: {},
      pagi: "",
      filter: {
        pagiurl: "",

        listNum: "",
        search: "",
      },
      form: {
        id: "",
        status: "",
      },
      isInputSearch: false,
      sort: {
        key: "",
        isAsc: false,
      },
    };
  },
  mounted() {
    Fire.$on("doneProcess", () => {
      this.getData(this.filter.pagiurl);
    });
    this.filter.listNum = this.$cookies.get("process_listnum");
    this.getData(this.api_url);
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.search) {
          this.isInputSearch = true;
        } else {
          this.isInputSearch = false;
        }
      },
    },
  },

  computed: {
    ...mapGetters(["allProcesses"]),
    sortedItems() {
      const list = this.allProcesses.slice();
      for (let i = 0; i < list.length; i++) {
        list[i].order = this.$getOverallIndex(i, this.pagination);
      }
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key];
          b = b[this.sort.key];

          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1);
        });
      }

      return list;
    },
  },

  methods: {
    ...mapActions([
      "fetchProcesses",
      "deleteProcess",
      "addProcess",
      "updateProcess",
    ]),

    toDelete(id) {
      swal
        .fire({
          title: this.translate("menu2.are_you_sure?"),
          text: this.translate("menu2.you_wont_get_back"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3490dc",
          cancelButtonColor: "#FF5A5F",
          confirmButtonText: this.translate("menu2.yes_delete_it"),
          cancelButtonText: this.translate("menu2.cancel"),
        })
        .then((result) => {
          if (result.isConfirmed && result.value) {
            this.deleteProcess(id)
              .then((response) => {
                if (response.data.success) {
                  swal.fire(
                    this.translate("menu2.delete"),
                    response.data.message,
                    "success"
                  );
                } else {
                  swal.fire(
                    this.translate("menu2.error"),
                    response.data.message,
                    "error"
                  );
                }
              })
              .catch((error) => {
                if (error.response) {
                  swal.fire(
                    this.translate("menu2.error"),
                    error.response.data.message,
                    "warning"
                  );
                }
              });
          }
        });
    },
    openModal() {
      this.$refs.ProcessingModal.openModal();
    },
    toEditModal(user) {
      this.$refs.ProcessingModal.openModalEdit(user);
    },
    getData(pagi) {
      this.filter.pagiurl = pagi || this.api_url;
      this.fetchProcesses(this.filter)
        .then((response) => {
          this.filter.listNum = response.data.listNum;
          this.pagination = this.$paginate(response.data.message);
        })
        .catch((error) => {
          if (error.response) {
            swal.fire(
              this.translate("menu2.error"),
              error.response.data.message,
              "warning"
            );
          }
        });
    },
    getOverallIndex(index) {
      return (
        this.pagination.current_page * this.pagination.per_page -
        this.pagination.per_page +
        index +
        1
      );
    },
    searchKey() {
      this.filter.pagiurl = this.api_url;
      this.getData(this.filter.pagiurl);
    },
    clearSearch() {
      this.filter.search = "";
      this.getData(this.filter.pagiurl);
    },
    changeList(num) {
      this.$cookies.set("location_listnum", num);
      this.filter.listNum = num;
      this.getData(this.filter.pagiurl);
    },
    //End filter
  },
};
</script>


<style lang="scss" scoped>
.btn-action {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 3px;
  .btn-edit {
    color: green;
  }
  .btn-delete {
    color: red;
  }
}
.title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-loc {
  height: 40px;
  text-align: center;
  background: #55b2be;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  color: white;
  font-size: 16px;

  & i {
    margin-right: 9px;
    font-size: 20px;
  }
}
</style>
</style>

